import React, { memo, useEffect, useState, useCallback } from 'react';
import { Form, Slider, Input, Checkbox, Select, Switch, InputNumber, Button, DatePicker, Upload } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ru_RU';
import makeNumberPretty from '../../../../heplers/makeNumberPretty';
import { getCurentData } from '../../../../heplers/dataCoverter';
import { getCreditPaymentTable } from '../../../../redux/ducks/user';
import LoanTableModal from '../../Modals/LoanTableModal/LoanTableModal';
import LoanTermsModal from '../../Modals/LoanTermsModal/LoanTermsModal';
import { createSpecificMessage } from '../../../../redux/ducks/user';

const { TextArea } = Input;
const { Option } = Select;

const LoanForm = ({
  form,
  setDisabled,
  sliderValues,
  setSliderValues,
  isLoading,
  loanType,
  changeLoanType,
  needHideLoanCheckbox,
}) => {
  // const userData = useSelector((state) => state.user.credit);
  const loans = useSelector((state) => state.user.credit.loans);
  const requisites = useSelector((state) => state.user.credit.requisites);
  const dispatch = useDispatch();
  const [ loanTable, setLoanTable ] = useState(false);
  const [ fileList, setFileList ] = useState([]);

  // const [getImageFile, setImageFile] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [buttonEnabled2, setButtonEnabled2] = useState(true);
  const [buttonEnabled3, setButtonEnabled3] = useState(true);

  const config = useSelector((state) => state.config);

  

	const props = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
      console.log(fileList);
      if (!fileList[1]) {
        setButtonEnabled(true);
      } else if (!fileList[2]) {
        setButtonEnabled2(true);
      } else if (!fileList[3]) {
        setButtonEnabled3(true);
      }
		},
		beforeUpload: (file) => {
      // file.push('Фото с паспортом');
			let a = [ ...fileList, file ];
      // fileList[0].push('Фото с паспортом');
      // a.name.push('Фото с паспортом');
      // let b = fileList.indexOf(file);
      // let c = b.name;
      // let fname = new File(a, "Фото с паспортом");
			setFileList(a);
      if (!a[1]) {
        setButtonEnabled(false);
      } else if (!a[2]) {
        setButtonEnabled2(false);
      } else if (!a[3]) {
        setButtonEnabled3(false);
      }

      // a[0].name = 'Фото с паспортом';
      // setFileList([...fname]);
      // console.log('console', fname);
      
			return false;
		},
    
		fileList
	};
  // const clickHandler = () => {
  //   setButtonEnabled(false);
  // };

  // const resetHandler = () => {
  //   setButtonEnabled(true);
  // };
  // const resetHandler2 = () => {
  //   setButtonEnabled2(true);
  // };
  // const resetHandler3 = () => {
  //   setButtonEnabled3(true);
  // };
  const normFile = (e) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    // 	return e && e.fileList;
    return e?.fileList;
  };
	
  // const handleClick = ({target}) => {
    // console.log(getImageFile);
    // const file = new File(getImageFile, "Фото с паспортом");
    
    // const file = new File(fileList, "Фото с паспортом");
    // const fileNames  = Array.from(target.fileList).map(file => file.name)
    // console.log(`Selected files - ${normFile.join(', ')}`);
    // setFiles(fileNames);
    // console.log(file);
  // };

  console.log(fileList);
  const getCreditTableData = useCallback(() => {
    let formData = form.getFieldsValue();
    let vals = {
      ВидЗайма: {
        UIDЗайма: loanType.UIDЗайма,
      },
      Срок: formData.Срок,
      Сумма: formData.Сумма,
      АвансРассрочки: formData.АвансРассрочки,
      ОжидаемаяДатаВыдачи: getCurentData(),
      РасчетПСК: false,
    };
    // console.log(vals)
    dispatch(getCreditPaymentTable(vals));
    setLoanTable(true);
  }, [form, loanType.UIDЗайма, dispatch]);

  const specificMessage = useSelector((state) => state.user.credit.specificMessage);

  const [IsLoanTermsVisible, setIsLoanTermsVisible] = useState(false);
  
  useEffect(() => {
    if (sliderValues.first) {
      let c = loanType.СуммаМакс - loanType.СуммаМин;
      let d = c / 100;
      let h = sliderValues.first * d;
      let a = h + +loanType.СуммаМин;
      // console.log(a);
      let g = Math.round(a / 1000) * 1000;
      // let b = makeNumberPretty(g);
      let x = (g * 25) / 100;
      form.setFieldsValue({ Сумма: g });
      form.setFieldsValue({ АвансРассрочки: x });
    } else {
      form.setFieldsValue({ Сумма: loanType.СуммаМин });
      form.setFieldsValue({ Сумма: loanType.АвансМин });
    }
  }, [sliderValues.first, loanType, form]);

  useEffect(() => {
    // console.log(sliderValues.second)
    if (sliderValues.second) {
      let a =
        sliderValues.second * ((loanType.СрокМакс - loanType.СрокМин) / 100) + +loanType.СрокМин;
      form.setFieldsValue({ Срок: Math.ceil(a) });
    } else {
      form.setFieldsValue({ Срок: loanType.СрокМин });
    }
  }, [sliderValues.second, loanType, form]);

  useEffect(() => {
    if (sliderValues.third) {
      let c = loanType.СуммаМакс - loanType.СуммаМин;
      let d = c / 100;
      let h = sliderValues.third * d;
      let a = h + +loanType.СуммаМин;
      // console.log(a);
      let g = Math.round(a / 1000) * 1000;

      let j = sliderValues.third * ((loanType.АвансМакс - loanType.АвансМин) / 100) + +loanType.АвансМин;
      let z = g;
      let x = z * Math.ceil(j) / 100;
      // let b = makeNumberPretty(g);
      form.setFieldsValue({ АвансРассрочки: x });
      // console.log('useEffect:', x);
    } else {
      form.setFieldsValue({ АвансРассрочки: loanType.АвансМин });
    }
  }, [sliderValues.third, loanType, form]);

  const marks = {
    0: loanType.СуммаМин ? makeNumberPretty(loanType.СуммаМин) : '0',
    100: {
      style: {
        whiteSpace: 'nowrap',
      },
      label: <>{loanType.СуммаМакс ? makeNumberPretty(loanType.СуммаМакс) : '100'}</>,
    },
  };

  const marks2 = {
    0: loanType.СрокМин ? `${loanType.СрокМин} ${loanType.ЕдИзмСрока}` : `1 ${loanType.ЕдИзмСрока}`,
    100: {
      style: {
        whiteSpace: 'nowrap',
      },
      label: (
        <>
          {loanType.СрокМакс
            ? `${loanType.СрокМакс} ${loanType.ЕдИзмСрока}`
            : `12 ${loanType.ЕдИзмСрока}`}
        </>
      ),
    },
  };

  const avans = {
    0: loanType.АвансМин ? `${loanType.АвансМин} ${'%'}` : `1 ${'%'}`,
    100: {
      style: {
        whiteSpace: 'nowrap',
      },
      label: (
        <>
          {loanType.АвансМакс
            ? `${loanType.АвансМакс} ${'%'}`
            : `90 ${'%'}`}
        </>
      ),
    },
  };

  return (
    <>
      <Form
        form={form}
        layout='vertical' // "horizontal"
        name='loan_modal'
        initialValues={{
          ЕдИзмСрока: loanType.ЕдИзмСрока,
        }}
        size={'middle'}
      >
        <Form.Item name='ВидЗайма' label='Вид тарифа'>
          <Select
            placeholder='Выберите вид тарифа'
            onSelect={(a, b) => {
              changeLoanType(b['data-el']);
              setSliderValues({ first: 0, second: 0, third: 0 });
            }}
          >
            {loans.Займ && Array.isArray(loans.Займ) ? (
              loans.Займ.map((el, ind) => (
                <Option key={el.Код + ind} value={el.НаименованиеДляПечати} data-el={el}>
                  {el.НаименованиеДляПечати}
                </Option>
              ))
            ) : (
              <Option
                key={loans.Займ.Код}
                value={loans.Займ.НаименованиеДляПечати}
                data-el={loans.Займ}
              >
                {loans.Займ.НаименованиеДляПечати}
              </Option>
            )}
          </Select>
        </Form.Item>
        
        {loanType.UIDЗайма && (
          <>
            {/* Какая сумма Вам нужна? */}
            <div className='loan-input-group'>
              <Form.Item name='ЕдИзмСрока' className='loan-none'>
                <Input autoComplete='off' autoCorrect='off' spellCheck='false' readOnly />
              </Form.Item>
              <div className='loan-input-item'>
                <p>Стоимость товара</p>
                <Form.Item name='Сумма' className='loan-input'>
                  <InputNumber
                    // suffix='руб.'
                    type='number'
                    onFocus={(event) => event.target.select()}
                    min={loanType.СуммаМин}
                    max={loanType.СуммаМакс}
                    // formatter={(value) =>
                    //   // `${value} руб.`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
                    //   `${value}`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
                    // }
                    formatter={(value) =>
                      // `${value} руб.`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
                      `${value}`
                    }
                    // parser={(value) => value.replace(/(\s*)руб.?|(\s*)/g, '')}
                    parser={(value) => value.replace(/(\s*)?|(\s*)/g, '')}
                    onChange={(val) => {
                      if (val) {
                        let c = loanType.СуммаМакс - loanType.СуммаМин;
                        let d = c / 100;
                        let g = (val - loanType.СуммаМин) / d;
                        let b = (val * 25) / 1000;
                        setSliderValues({ ...sliderValues, first: g });

                        console.log(b);
                        
                      } else {
                        form.setFieldsValue({ Сумма: loanType.СуммаМин });
                        setSliderValues({ ...sliderValues, first: 0 });
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <Slider
                onChange={(val) => {
                  setSliderValues({ ...sliderValues, first: val });
                  console.log(sliderValues.first);
                }}
                defaultValue={0}
                step={0.1}
                marks={marks}
                tooltipVisible={false}
                value={sliderValues.first}
                disabled={isLoading}
              />
            </div>

            {/* На какой период? */}
            <div className='loan-input-group loan-input-group-month'>
              <div className='loan-input-item'>
                <p>На какой период?</p>
                <Form.Item name='Срок' className='loan-input'>
                  <InputNumber
                    type='number'
                    onFocus={(event) => event.target.select()}
                    min={loanType.СрокМин}
                    max={loanType.СрокМакс}
                    // formatter={(value) => `${value} ${loanType.ЕдИзмСрока}`}
                    formatter={(value) => `${value}`}
                    // parser={(value) => loanType.ЕдИзмСрока === 'мес.' ? value.replace(/(\s*)мес./g, '') : value.replace(/(\s*)дн./g, '')}
                    parser={(value) =>
                      // value.replace(new RegExp(`(\\s*)${loanType.ЕдИзмСрока}`, 'g'), '')
                      value.replace(new RegExp(`(\\s*)`, 'g'), '')
                    }
                    onChange={(val) => {
                      if (val) {
                        let c = loanType.СрокМакс - loanType.СрокМин;
                        let d = c / 100;
                        let g = (val - loanType.СрокМин) / d;
                        setSliderValues({ ...sliderValues, second: g });
                      } else {
                        form.setFieldsValue({ Срок: loanType.СрокМин });
                        setSliderValues({ ...sliderValues, second: 0 });
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <Slider
                onChange={(val) => {
                  setSliderValues({ ...sliderValues, second: val });
                }}
                defaultValue={0}
                step={(loanType.СрокМакс - loanType.СрокМин) / 100}
                marks={marks2}
                tooltipVisible={false}
                value={sliderValues.second}
                disabled={isLoading}
              />
            </div>
            {/* Авансовый платеж */}
            <div className='loan-input-group'>
                      
              <div className='loan-input-item'>
                        <p>Авансовый платеж</p>
                        <Form.Item name='АвансРассрочки' className='loan-input'>
                          <InputNumber
                            // suffix='руб.'
                            onFocus={(event) => event.target.select()}
                            type='number'
                            min='0'
                            max={(loanType.СуммаМакс * loanType.АвансМакс) / 100 }
                            // readOnly
                            // formatter={(value) => `${value} ${'%'}`}
                            // parser={(value) =>
                            //   value.replace(new RegExp(`(\\s*)${'%'}`, 'g'), '')
                            // }
                            // formatter={(value) =>
                            //   // `${value} руб.`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
                            //   `${value}`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
                            // }
                            formatter={(value) =>
                              // `${value} руб.`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
                              `${value}`
                            }
                            // parser={(value) => value.replace(/(\s*)руб.?|(\s*)/g, '')}
                            parser={(value) => value.replace(/(\s*)?|(\s*)/g, '')}
                            onChange={(val) => {
                              if (val) {
                                

                                let c = loanType.Сумма;
                                let d = c / 100;
                                let g = (val - loanType.СуммаМин) / d;
                                let z = g * loanType.АвансМакс;
                                console.log(g);
                                setSliderValues({ ...sliderValues, third: z });

                              } else {
                                form.setFieldsValue({ Аванс: loanType.АвансМин });
                                setSliderValues({ ...sliderValues, third: 0 });
                              }
                            }}
                          />
                        </Form.Item>
              </div>
                      <Slider
                        onChange={(val) => {
                          setSliderValues({ ...sliderValues, third: val });
                          // console.log('value: ', sliderValues.first);
                        }}
                        defaultValue={3}
                        
                        step={1}
                        marks={avans}
                        value={sliderValues.third}
                        tooltipVisible={false}
                        disabled={isLoading}
                      />
            </div>
            <div className='loan__graph-btn'>
              <Button type='link' className='btn-info' onClick={getCreditTableData}>
                Показать график платежей
              </Button>
            </div>

            {/* Динамические данные из реквизитов */}
            {requisites &&
              requisites.Реквизит &&
              requisites.Реквизит.filter((el) => !needHideLoanCheckbox ? !el.БезАвторизации : el).map((el) => {
                if (el.ВнутреннийТип === 'Дата рождения') {
                  return (
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      rules={
                        el.Обязателен && [
                          {
                            required: true,
                            message: `Заполните поле`,
                          },
                        ]
                      }
                    >
                      <DatePicker locale={locale} format='DD.MM.YYYY' />
                    </Form.Item>
                  );
                } else if (el.ВнутреннийТип === 'Мобильный телефон') {
                  return (
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      rules={[
                        el.Обязателен
                          ? {
                              required: true,
                              message: 'Заполните номер мобильного телефона',
                            }
                          : {},
                        { max: 10, message: 'Номер слишком длинный' },
                        { min: 10, message: 'Номер слишком короткий' },
                      ]}
                      // hasFeedback
                    >
                      <Input
                        addonBefore='+7'
                        type='number'
                        autoComplete='off'
                        autoCorrect='off'
                        spellCheck='false'
                        allowClear
                      />
                    </Form.Item>
                  );
                } else if (el.Наименование === 'Авансовый платеж') {
                  
                  
                } else if (el.ВнутреннийТип === 'Произвольный реквизит (булево)') {
                  return (
                    // <Form.Item
                    //   key={el.UIDРеквизита}
                    //   name={el.Наименование}
                    //   label={el.Наименование}
                    //   valuePropName='checked'
                    //   {...tailLayout}
                    // >
                    // <Checkbox readOnly={isLoading} />
                    // </Form.Item>
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                  );
                } else {
                  // console.log('ВнутреннийТип: ', el.ВнутреннийТип);
                  // console.log('el: ', el);
                  if (el.Наименование !== 'ФИО отца') {
                    return (
                      <Form.Item
                        key={el.UIDРеквизита}
                        name={el.Наименование}
                        label={el.Наименование}
                        rules={
                          el.Обязателен && [
                            {
                              required: true,
                              message: `Заполните поле`,
                            },
                          ]
                        }
                      >
                        <Input
                          type={
                            el.ВнутреннийТип === 'Произвольный реквизит (число)'
                              ? 'number'
                              : el.ВнутреннийТип === 'Произвольный реквизит (строка)'
                              ? 'string'
                              : el.ВнутреннийТип === 'СНИЛС'
                              ? 'string'
                              : 'string'
                          }
                          autoComplete='off'
                          autoCorrect='off'
                          spellCheck='false'
                          allowClear
                          // onChange={(val) => console.log(val)}
                        />
                      </Form.Item>
                    );
                  }
                }
              })}
            {/* Загрузка файлов */}
        {config['1c_configs'].return.НастройкиЗаявокНаЗайм.ОтображатьПрисоединениеФайлов && 
             
             <label style={{ display: 'block' }} htmlFor="msg_modal_upload">
              <Form.Item>
                <Form.Item
                  name="upload"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  label="Фото с паспортом"
                  rules={[
                    {
                      required: true,
                      message: 'Загрузите фото с паспортом',
                    }
                   ]}
                  
                >
                  <Upload
                    name="photo1"
                    multiple={false}
                    accept="image/*"
                    listType="picture"
                    // onChange={handleClick}
                    // disabled={!buttonEnabled}
                    maxCount={1}
                    // onRemove={resetHandler}
                    
                     {...props}
                  >
                    <Button 
                      disabled={!buttonEnabled} 
                      
                    >
                      <UploadOutlined /> Прикрепить файлы
                    </Button>
                  </Upload>
                </Form.Item>
          
                <Form.Item
                  name="upload1"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  label="Фото разворота паспорта"
                  rules={[
                    {
                      required: true,
                      message: 'Загрузите фото разворота паспорта',
                    }
                   ]}
                >
                  <Upload
                    name="photo2"
                    multiple={false}
                    accept="image/*"
                    // customRequest={uploadImage}
                    listType="picture"
                    // onChange={handleClick}
                    maxCount={1}
                    // onRemove={resetHandler2}
                    //  fileList={fileList}
                    {...props}
                  >
                    <Button 
                      disabled={!buttonEnabled2} 
                      //  onClick={handleClick}
                    >
                      <UploadOutlined /> Прикрепить файлы
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  name="upload2"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  label="Фото последнего места регистрации в паспорте"
                  rules={[
                    {
                      required: true,
                      message: 'Загрузите фото последнего места регистрации в паспорте',
                    }
                   ]}
                >
                  <Upload
                    name="photo3"
                    multiple={false}
                    accept="image/*"
                    listType="picture"
                    // onChange={handleClick}
                    maxCount={1}
                    // onRemove={resetHandler3}
                    //  fileList={fileList}
                    {...props}
                  >
                    <Button 
                      disabled={!buttonEnabled3} 
                      //  onClick={handleClick}
                    >
                      <UploadOutlined /> Прикрепить файлы
                    </Button>
                  </Upload>

                </Form.Item>
              </Form.Item>
              
             </label>
           }
            {/* Загрузка файлов */}
            {/* {config['1c_configs'].return.НастройкиЗаявокНаЗайм.ОтображатьПрисоединениеФайлов && 
             
             <label style={{ display: 'block' }} htmlFor="msg_modal_upload">
               <Form.Item
                 name="upload"
                 
                 valuePropName="fileList"
                 getValueFromEvent={normFile}
                 
                 // extra="longggggggggggggggggggggggggggqgggggggg"
               >
                 <p>Фото с паспортом</p>
                 <Upload
                   name="logo"
                   multiple={false}
                   // accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, .doc, .docx, .odt, .pdf, .xls, .xlsx, .ods, .numbers"
                   accept="image/*"
                   listType="picture"
                   
                  //  onChange={handleClick}
                  //  fileList={fileList}
                   {...props}
                 >
                   
                   <Button 
                     disabled={isLoading} 
                   >
                     <UploadOutlined /> Прикрепить файлы
                   </Button>
                 </Upload>
               </Form.Item>
             </label>
           } */}

            {/* Комментарий */}
            <div className='loan-comment'>
              <p>Комментарий</p>
              <Form.Item name='Описание' className='loan-input'>
                <TextArea rows={4} readOnly={isLoading} />
              </Form.Item>
            </div>

            {/* Подтверждение */}
            {!needHideLoanCheckbox ? (
              <div className='loan-input-item loan-input-item__start'>
                <Form.Item name='remember' valuePropName='checked'>
                  <Checkbox
                    readOnly={isLoading}
                    onChange={(e) => {
                      if (e.target.checked && !specificMessage.UIDСообщения) {
                        dispatch(createSpecificMessage({ type: 'УсловияВыдачиЗайма' }));
                      }
                      setDisabled(!e.target.checked);
                    }}
                    className='loan-input-item__start-check'
                  >
                    <Button
                      type='link'
                      onClick={() => {
                        if (!specificMessage.UIDСообщения) {
                          dispatch(createSpecificMessage({ type: 'УсловияВыдачиЗайма' }));
                        }
                        setIsLoanTermsVisible(true);
                      }}
                    >
                      С условиями выдачи ознакомлен и согласен
                    </Button>
                    {/* <p>
                    <span className='sms-error'>*</span> Нажимая кнопку "Отправить заявку на
                    рассмотрение" я даю согласие на отправку запроса в бюро кредитных историй и
                    обработку персональных данных, с тарифами и условиями выдачи займа ознакомлен и
                    согласен.
                  </p> */}
                  </Checkbox>
                </Form.Item>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </Form>

      <LoanTableModal visible={loanTable} onClose={() => setLoanTable(false)} sum={() => form.getFieldsValue().Сумма}/>

      {/* Модалка документов из спецсообщения с условиями выдачи*/}
      <LoanTermsModal
        visible={IsLoanTermsVisible}
        onCancel={() => setIsLoanTermsVisible(false)}
        title='Условия выдачи рассрочки'
      />
    </>
  );
};

export default memo(LoanForm);
