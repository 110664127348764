import axios from 'axios';
// import {
//   notification
// } from 'antd';
import moment from 'moment';
import { changeGlobalError } from './globalError';
import getDefaultObject from '../../heplers/getDefaultObject';
import downloadFileFromUrl from '../../heplers/downloadFileFromUrl'
import { setFileIsLoading } from './messages';
const BASE_API_PATH = process.env.REACT_APP_API_URL;

// const INN = process.env.REACT_APP_INN;
// const APP_VERSION = process.env.REACT_APP_APP_VERSION;

const defaultHeader = {
	headers: {
		'Content-type': 'application/json'
	}
};

// Action types
const ADD_USER = 'USER/ Add user data';
const CLEAR_USER = 'USER/ Remove user data';
const ADD_USER_SERVER_RESPONSE = 'USER/ Add user server response';
const CLEAR_USER_SERVER_RESPONSE = 'USER/ Clear user server response';

const ADD_REQUISITES = 'CREDITS/ Add requisites';
const ADD_LOANS = 'CREDITS/ Add loans';
const ADD_FILE = 'FILE/ Add file';
const CLEAR_FILE = 'FILE/ Clear file';

const ADD_DEPOSIT_REQUISITES = 'DEPOSITS/ Add requisites';
const ADD_DEPOSIT_TYPES = 'DEPOSITS/ Add deposit types';

const ADD_CREDIT_PAYMENT_TABLE = 'CREDITS/ Add credits payment table';
const CLEAR_CREDIT_PAYMENT_TABLE = 'CREDITS/ Clear credits payment table';
const SET_ESIA = 'ESIA/ESIA Register';
const ADD_ESIA_LOAN_TERMS = 'ESIA/Add esia loan terms';

const ADD_SPECIFIC_MESSAGE = 'LOAN/ Add specific message';
const CLEAR_SPECIFIC_MESSAGE = 'LOAN/ Clear specific message';
const SET_SPECIFIC_IS_LOADING = 'LOAN/ Set specific is loading flag';

// Reducer
const inititalState = {
	data: {},
	serverResponse: {},
	credit: {
		requisites: {},
		loans: {},
		file: '',
		creditTable: null,
		loanTerms: {},
		specificMessage: {},
		specificIsLoading: false,
	},
	deposit: {
		requisites: {},
		types: {}
	},
	esia: {
		is: false,
		code: null,
	}
};

const user = (state = inititalState, action) => {
	switch (action.type) {
		case ADD_USER:
			return {
				...state,
				data: action.payload
			};
		case CLEAR_USER:
			return {
				...inititalState
			};
		case ADD_USER_SERVER_RESPONSE:
			return {
				...state,
				serverResponse: action.payload,
				isLoading: false
			};
		case CLEAR_USER_SERVER_RESPONSE:
			return {
				...state,
				serverResponse: {}
			};
		case ADD_REQUISITES:
			return {
				...state,
				credit: {
					...state.credit,
					requisites: action.payload
				}
			};
		case ADD_LOANS:
			return {
				...state,
				credit: {
					...state.credit,
					loans: action.payload
				}
			};
		case ADD_FILE:
			return {
				...state,
				credit: {
					...state.credit,
					file: action.payload
				}
			};
		case CLEAR_FILE:
			return {
				...state,
				credit: {
					...state.credit,
					file: ''
				}
			};
		case ADD_DEPOSIT_REQUISITES:
			return {
				...state,
				deposit: {
					...state.deposit,
					requisites: action.payload
				}
			};
		case ADD_DEPOSIT_TYPES:
			return {
				...state,
				deposit: {
					...state.deposit,
					types: action.payload
				}
			};
		case ADD_CREDIT_PAYMENT_TABLE:
			return {
				...state,
				credit: {
					...state.credit,
					creditTable: action.payload
				}
			};
		case CLEAR_CREDIT_PAYMENT_TABLE:
			return {
				...state,
				credit: {
					...state.credit,
					creditTable: null
				}
			};
		case SET_ESIA:
			return {
				...state,
				esia: {...action.payload}
			};
		case ADD_ESIA_LOAN_TERMS:
			return {
				...state,
				credit: {
					...state.credit,
					loanTerms: action.payload
				}
			};
		case ADD_SPECIFIC_MESSAGE:
			return {
				...state,
				credit: {
					...state.credit,
					specificMessage: action.payload
				}
			};
		case CLEAR_SPECIFIC_MESSAGE:
			return {
				...state,
				credit: {
					...state.credit,
					specificMessage: {}
				}
			};
		case SET_SPECIFIC_IS_LOADING:
			return {
				...state,
				credit: {
					...state.credit,
					specificIsLoading: action.payload
				}
			};
		default:
			return state;
	}
};

// Actions

///// SIMPLE ACTIONS //////

export const clearUserServerResponse = () => {
	return {
		type: CLEAR_USER_SERVER_RESPONSE
	};
};

export const clearUserData = () => {
	return {
		type: CLEAR_USER
	};
};

export const clearFile = () => {
	return {
		type: CLEAR_FILE
	};
};

// Register user
const getRegisterData = (vals) => {
	if (vals.register_type === 'ПоНомеруДоговораЗаймаИМобильному') {
		return `${vals.loan_agreement_number}|${vals.phone}`;
	} else if (vals.register_type === 'ПоНомеруДоговораЗаймаИНаименованию') {
		return `${vals.loan_agreement_number}|${vals.name}`;
	} else if (vals.register_type === 'ПоНомеруДоговораСбереженияИНаименованию') {
		return `${vals.savings_contract_number}|${vals.name}`;
	} else if (vals.register_type === 'ПоНаименованиюИМобильному') {
		return `${vals.name}|${vals.phone}`;
	} else {
		return '';
	}
};

////// COMPLEX ACTIONS //////

/////////////////// АВТОРИЗАЦИЯ //////////////////////

// Логинизация обычная через форму
export const signIn = (vals) => (dispatch) => {
	let config = JSON.parse(localStorage.getItem('conf'));
	const INN = config.code;
	const APP_VERSION = config.app_version;

	let obj = {
		ИННОрганизации: INN,
		ВерсияПриложения: APP_VERSION,
		ТипАвторизации: 'ПоМобильномуИПаролю',
		ДанныеАвторизации: `${vals.phone}|${vals.password}`
	};

	return axios
		.post(`${BASE_API_PATH}/auth/login`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						status: 'error'
					}
				});
			} else {
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						...res,
						phone: vals.phone
					}
				});
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

// Регистрация нового юзера в модалке
export const signUp = (vals) => (dispatch) => {
	let config = JSON.parse(localStorage.getItem('conf'));
	const INN = config.code;
	const APP_VERSION = config.app_version;

	let obj = {
		ИННОрганизации: INN,
		ВерсияПриложения: APP_VERSION,
		ТипАвторизации: vals.register_type,
		ДанныеАвторизации: getRegisterData(vals),
		Пароль: vals.password,
		Тест: vals.Тест
	};

	return axios
		.post(`${BASE_API_PATH}/auth/register`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						status: 'error'
					}
				});
			} else {
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: res
				});
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

// Восстановление пароля.
export const restorePassword = (vals) => (dispatch) => {
	let config = JSON.parse(localStorage.getItem('conf'));
	const INN = config.code;
	const APP_VERSION = config.app_version;

	let obj = {
		ИННОрганизации: INN,
		ВерсияПриложения: APP_VERSION,
		ТипАвторизации: vals.register_type,
		ДанныеАвторизации: getRegisterData(vals),
		Пароль: vals.password,
		Тест: vals.Тест,
		Повторно: true
	};

	return axios
		.post(`${BASE_API_PATH}/auth/register`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						status: 'error'
					}
				});
			} else {
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: res
				});
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

// Изменение пароля
export const changePassword = (vals) => (dispatch) => {
	let obj = {
		...getDefaultObject(),
		Пароль: vals.password
	};

	return axios
		.post(`${BASE_API_PATH}/user/change_password`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						status: 'error'
					}
				});
			} else {
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: res
				});
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};


/////////////////// DATA //////////////////////

// ОСНОВНАЯ АПИШКА ПОЛУЧЕНИЯ ВСЕХ ДАННЫХ ЮЗЕРА
export const getUserData = () => (dispatch) => {
	let obj = getDefaultObject();

	return axios
		.post(`${BASE_API_PATH}/user/get`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						status: 'error'
					}
				});
			} else {
				if (res.data.return.КодОшибки === '0') {
					dispatch({
						type: ADD_USER,
						payload: res.data.return
					});
				} else {
					if (res.data.return.КодОшибки === '-1' || res.data.return.КодОшибки === '-2') {
						dispatch(changeGlobalError('session_fail'));
					}
				}
			}
		})
		.catch((err) => {
			console.log(err);
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

// LOANS HISTORY //

// Получить реквизиты для Заявки на Займ
export const getRequisites = () => (dispatch) => {
	let obj = getDefaultObject();

	// console.log(obj)
	return axios
		.post(`${BASE_API_PATH}/credit/get_requisites`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки === '0') {
					let payl = [];
					if(Array.isArray(res.data.return.СписокРеквизитов.Реквизит)) {
						payl = res.data.return.СписокРеквизитов.Реквизит
					} else {
						payl.push(res.data.return.СписокРеквизитов.Реквизит)
					};
					dispatch({
						type: ADD_REQUISITES,
						payload: {
							Реквизит: payl,
						}
					});
				} else {
					// notification.error({
					//   message: 'Ошибка',
					//   description: 'Не удалось получить реквизиты займа',
					// });
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

// Получить виды займов (первая выпадашка в модалке Заявки на займ)
export const getLoans = () => (dispatch) => {
	let obj = getDefaultObject();
	// console.log(obj)
	return axios
		.post(`${BASE_API_PATH}/credit/get_products`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки === '0') {
					dispatch({
						type: ADD_LOANS,
						payload: res.data.return.СписокЗаймов
					});
				} else {
					// notification.error({
					//   message: 'Ошибка',
					//   description: res.data.return.ОписаниеОшибки,
					// });
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

// Создать заявку на займ
export const createLoan = (vals) => (dispatch) => {
	let obj = {
		...getDefaultObject(),
		Сумма: isNaN(vals.Сумма) ? vals.Сумма.split(' ').join('') : vals.Сумма,
		Срок: vals.Срок,
		ЕдИзмСрока: vals.ЕдИзмСрока,
		АвансРассрочки: vals.АвансРассрочки,
		ВидЗайма: vals.ТипВидЗайма,
		UIDСообщения: vals.UIDСообщения
	};

	if (vals.СписокЗначенийРеквизитов) {
		obj.СписокЗначенийРеквизитов = JSON.stringify(vals.СписокЗначенийРеквизитов)
	};

	if (vals.Описание) {
		obj.Описание = vals.Описание;
	};

	// if (vals.upload && vals.upload.length){
	// 	console.log(vals);
	// 	vals.upload.map((el, ind) => {
	// 		return obj[`file-${ind}`] = el.originFileObj
	// 	});
	// };
	// if (vals.upload && vals.upload.length){
	// 	console.log(vals);
	// 	vals.upload.map((el, ind) => {
	// 		return obj[`СканРазворотаПаспорта`] = el.originFileObj
	// 	});
	// 	vals.upload1.map((el, ind) => {
	// 		return obj[`СканПропискиПаспорта`] = el.originFileObj
	// 	});
	// 	vals.upload2.map((el, ind) => {
	// 		return obj[`СканСелфиСПаспортом`] = el.originFileObj
	// 	});
	// };
	if (vals.upload && vals.upload.length){
		console.log(vals);
		vals.upload.map((el, ind) => {
			return obj[`file-0`] = el.originFileObj
		});
		vals.upload1.map((el, ind) => {
			return obj[`file-1`] = el.originFileObj
		});
		vals.upload2.map((el, ind) => {
			return obj[`file-2`] = el.originFileObj
		});
	};

	let arr = Object.entries(obj)
	let formData = new FormData();
	arr.map(el => {
		if (el[0].includes('file') || typeof obj[el[0]] !== 'object') {
			formData.append(el[0], obj[el[0]]);
		} else {
			let a = Object.entries(obj[el[0]]);
			a.map(ell => {
				formData.append(`${el[0]}[${ell[0]}]`, ell[1]);
				return ell
			});
		};
    return el
	});
	console.log(`${BASE_API_PATH}/credit/send_request`, obj);
	console.log(`form`, formData);

	return axios
		.post(`${BASE_API_PATH}/credit/send_request`, formData, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						status: 'error'
					}
				});
			} else {
				// console.log(JSON.parse(res.data));
				if (res.data.return.КодОшибки !== '-1' && res.data.return.КодОшибки !== '-2') {
					dispatch({
						type: ADD_USER_SERVER_RESPONSE,
						payload: res
					});
				} else {
					dispatch(changeGlobalError('session_fail'));
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

// DEPOSIT HISTORY //
// Получить реквизиты для Заявки на сбережения
export const getDepositRequisites = () => (dispatch) => {
	let obj = getDefaultObject();

	// console.log(obj)
	return axios
		.post(`${BASE_API_PATH}/deposit/get_request_requisites`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки === '0') {
					dispatch({
						type: ADD_DEPOSIT_REQUISITES,
						payload: res.data.return.СписокРеквизитов
					});
				} else {
					// notification.error({
					//   message: 'Ошибка',
					//   description: 'Не удалось получить реквизиты займа',
					// });
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

// Получить виды сбережений
export const getDepositTypes = () => (dispatch) => {
	let obj = getDefaultObject();
	// console.log(obj)
	return axios
		.post(`${BASE_API_PATH}/deposit/get_products`, obj, defaultHeader)
		.then((res) => {
			// console.log(res)
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки === '0') {
					dispatch({
						type: ADD_DEPOSIT_TYPES,
						payload: res.data.return.СписокСбережений
					});
				} else {
					// notification.error({
					//   message: 'Ошибка',
					//   description: res.data.return.ОписаниеОшибки,
					// });
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

// Создать заявку на сбережения
export const createDepositRequest = (vals) => (dispatch) => {
	let obj = {
		...getDefaultObject(),
		Сумма: isNaN(vals.Сумма) ? vals.Сумма.split(' ').join('') : vals.Сумма,
		Срок: vals.Срок,
		ЕдИзмСрока: vals.ЕдИзмСрока,
		ВидСбережения: vals.ВидСбережения,
	};

	// проверяем доп реквезиты и переводим в строку JSON
	if (vals.СписокЗначенийРеквизитов) {
		obj.СписокЗначенийРеквизитов = JSON.stringify(vals.СписокЗначенийРеквизитов)
	}


	if (vals.Описание) {
		obj.Описание = vals.Описание;
	}

	if (vals.upload && vals.upload.length){
		vals.upload.map((el, ind) => {
			return obj[`file-${ind}`] = el.originFileObj
		});
	};

	// console.log(JSON.stringify(obj));
	// console.log(obj)

	let arr = Object.entries(obj)
  let formData = new FormData();
  arr.map(el => {
		if (el[0].includes('file') || typeof obj[el[0]] !== 'object') {
			formData.append(el[0], obj[el[0]]);
		} else {
			let a = Object.entries(obj[el[0]]);
			a.map(ell => {
				formData.append(`${el[0]}[${ell[0]}]`, ell[1]);
				return ell
			})
		}
    return el
	});
	// console.log(formData)

	return axios
		.post(`${BASE_API_PATH}/deposit/send_request`, formData, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						status: 'error'
					}
				});
			} else {
				// console.log(JSON.parse(res.data));
				if (res.data.return.КодОшибки !== '-1' && res.data.return.КодОшибки !== '-2') {
					dispatch({
						type: ADD_USER_SERVER_RESPONSE,
						payload: res
					});
				} else {
					dispatch(changeGlobalError('session_fail'));
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

//////////////// LOAN REPORTS ////////////////////

// Открыть в модалке файл займа (карточка займа, график платежей, справка-расчет)
export const getLoanReports = (vals) => (dispatch) => {
	let obj = {
		...getDefaultObject(),

		ОтчетКарточка: false,
		ОтчетСправка: false,
		ОтчетГрафик: false,
		ОтчетПоручители: false,
		ОтчетЗалогодатели: false,
		ОтчетРезерв: false,

		UIDДоговора: vals.UIDДоговора,
		ДатаРасчета: moment().format(),
		ФорматОтчета: 'HTML',

		...vals
	};

	// console.log(JSON.stringify(obj))

	return axios
		.post(`${BASE_API_PATH}​/credit/get_contract_info`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки !== '-1' && res.data.return.КодОшибки !== '-2') {
					dispatch({
						type: ADD_FILE,
						payload: res.data.return[vals.key]
					});
				} else {
					dispatch(changeGlobalError('session_fail'));
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};
// Скачать пдф файл нужного (карточка займа, график платежей, справка-расчет)
export const saveLoanReport = (vals) => (dispatch) => {
	let obj = {
		...getDefaultObject(),

		ОтчетКарточка: false,
		ОтчетСправка: false,
		ОтчетГрафик: false,
		ОтчетПоручители: false,
		ОтчетЗалогодатели: false,
		ОтчетРезерв: false,

		UIDДоговора: vals.UIDДоговора,
		ДатаРасчета: moment().format(),
		ФорматОтчета: 'HTML',
		...vals
	};
  dispatch(setFileIsLoading(true));
	return axios
		.post(`${BASE_API_PATH}​/credit/get_contract_info`, obj, defaultHeader)
		.then((res) => {
			dispatch(setFileIsLoading(false));
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки === '0') {
					dispatch({
						type: ADD_USER_SERVER_RESPONSE,
						payload: {
							status: 'success'
						}
					});
					let a = Object.keys(vals)
					downloadFileFromUrl(res.data.pdf_path, `${a[1]}.pdf`)
					// window.open(res.data.pdf_path);
				} else if (res.data.return.КодОшибки === '-1' || res.data.return.КодОшибки === '-2') {
					dispatch(changeGlobalError('session_fail'));
				} else {
				}
			}
		})
		.catch((err) => {
			console.log(err);
			dispatch(setFileIsLoading(false));
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

/////////////// DEPOSIT HISTORY /////////////////

// Открыть в модалке файл сережения (карточка сбережения, график платежей, справка-расчет)
export const getSavingsReports = (vals) => (dispatch) => {
	let obj = {
		...getDefaultObject(),

		ОтчетКарточка: false,
		ОтчетСправка: false,
		ОтчетГрафик: false,
		ОтчетПоручители: false,
		ОтчетЗалогодатели: false,
		ОтчетРезерв: false,

		UIDДоговора: vals.UIDДоговора,
		ДатаРасчета: moment().format(),
		ФорматОтчета: 'HTML',
		...vals
	};

	// console.log(JSON.stringify(obj))

	return axios
		.post(`${BASE_API_PATH}​/deposit/get_contract_info`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки !== '-1' && res.data.return.КодОшибки !== '-2') {
					dispatch({
						type: ADD_FILE,
						payload: res.data.return[vals.key]
					});
				} else {
					dispatch(changeGlobalError('session_fail'));
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};
// Скачать пдф файл нужного (карточка сбережения, график платежей, справка-расчет)
export const saveSavingsReport = (vals) => (dispatch) => {
	let obj = {
		...getDefaultObject(),

		ОтчетКарточка: false,
		ОтчетСправка: false,
		ОтчетГрафик: false,
		ОтчетПоручители: false,
		ОтчетЗалогодатели: false,
		ОтчетРезерв: false,

		UIDДоговора: vals.UIDДоговора,
		ДатаРасчета: moment().format(),
		ФорматОтчета: 'HTML',
		...vals
	};

	// console.log(JSON.stringify(obj))
  dispatch(setFileIsLoading(true));
	return axios
		.post(`${BASE_API_PATH}​/deposit/get_contract_info`, obj, defaultHeader)
		.then((res) => {
			dispatch(setFileIsLoading(false));
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки === '0') {
					dispatch({
						type: ADD_USER_SERVER_RESPONSE,
						payload: {
							status: 'success'
						}
					});
					let a = Object.keys(vals);
					downloadFileFromUrl(res.data.pdf_path, `${a[1]}.pdf`);
				} else if (res.data.return.КодОшибки === '-1' || res.data.return.КодОшибки === '-2') {
					dispatch(changeGlobalError('session_fail'));
				} else {
				}
			}
		})
		.catch((err) => {
			dispatch(setFileIsLoading(false));
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

export default user;

// Получить данные для графика платежей (модалка график платежей)
export const getCreditPaymentTable = (vals) => (dispatch) => {
	let obj = {
		...getDefaultObject(),
		...vals
	};
	// console.log(obj)
	return axios
		.post(`${BASE_API_PATH}/get_credit_payments_table `, obj, defaultHeader)
		.then((res) => {
			// console.log(res);
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки === '0') {
					dispatch({
						type: ADD_CREDIT_PAYMENT_TABLE,
						payload: res.data.return
					});
				} else {
					// notification.error({
					//   message: 'Ошибка',
					//   description: res.data.return.ОписаниеОшибки,
					// });
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};
///////////////////////////////////////// ESIA ///////////////////////////////////////////////

// Есиа авторизация, если да - внутрь ЛК, если нет - страница регистрации ЕСИА
export const esiaAuth = ({ code, goToMain, addUserToLocal, goToEsiaRegister, goToLogin }) => (dispatch) => {
	let obj = {
		auth_code: code
	};
	// console.log(obj)
	return axios
		.post(`${BASE_API_PATH}/esia/auth`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						status: 'error'
					}
				});
			} else {
				if (res.data.return.КодОшибки === '0') {
					// dispatch({
					// 	type: ADD_USER_SERVER_RESPONSE,
					// 	payload: {
					// 		...res,
					// 		phone: null
					// 	}
					// });
					addUserToLocal({ ...res.data.return, phone: null });
					goToMain();
				} else if (res.data.return.КодОшибки === '100') {
					dispatch({
						type: SET_ESIA,
						payload: { is: true, code: code }
					});
					goToEsiaRegister();
				} else {
					goToLogin()
					// console.log(res.data);
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				// dispatch({
				// 	type: SET_ESIA,
				// 	payload: { is: true, code: code }
				// });
				// goToEsiaRegister();
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

export const clearEsia = () => {
	return {
		type: SET_ESIA,
		payload: { is: false, code: null }
	};
};

// Заявка на займ, необходимые документы для ознакомиться, просто список, пока юзер не зарегистрирован
export const getESIALoanTerms = () => (dispatch) => {
	let config = JSON.parse(localStorage.getItem('conf'));
	const INN = config.code;
	const APP_VERSION = config.app_version;

	let obj = {
		ИННОрганизации: INN,
		ВерсияПриложения: APP_VERSION
	};
	// console.log(obj)
	return axios
		.post(`${BASE_API_PATH}/get_loan_terms`, obj, defaultHeader)
		.then((res) => {
			if (res.data.status && res.data.status === 'error') {
				dispatch(changeGlobalError('1C_error'));
			} else {
				if (res.data.return.КодОшибки === '0') {
					// console.log(res.data.return)
					dispatch({
						type: ADD_ESIA_LOAN_TERMS,
						payload: res.data.return.СписокПечатныхФорм
					});
				} else {
					// notification.error({
					//   message: 'Ошибка',
					//   description: res.data.return.ОписаниеОшибки,
					// });
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};

export const clearCreditPaymentTable = () => {
	return {
		type: CLEAR_CREDIT_PAYMENT_TABLE
	};
};
export const clearESIALoanTerms = () => {
	return {
		type: ADD_ESIA_LOAN_TERMS,
		payload: {}
	};
};

// Создать специальное сообщение для заявки на займ
export const createSpecificMessage = ( {type} ) => (dispatch, getState) => {
	let obj = {
		...getDefaultObject(),
		ТипСообщения: type
	};

	console.log(JSON.stringify(obj))
	const state = getState();
	console.log('--->', state, type);
	if(!state.user.credit.specificIsLoading) {

		dispatch({ type: SET_SPECIFIC_IS_LOADING, payload: true });
		return axios
			.post(`${BASE_API_PATH}​/create_specific_message`, obj, defaultHeader)
			.then((res) => {
				console.log('res axios: ', res, JSON.stringify(obj));
				if (res.data.status && res.data.status === 'error') {
					dispatch(changeGlobalError('1C_error'));
				} else {
					if (res.data.return.КодОшибки === '0') {
						dispatch({
							type: ADD_SPECIFIC_MESSAGE,
							payload: res.data.return.Сообщение
						});
					} else if (res.data.return.КодОшибки === '-1' || res.data.return.КодОшибки === '-2') {
						dispatch(changeGlobalError('session_fail'));
					} else if (res.data.return.КодОшибки === '-5') {
						dispatch(changeGlobalError('no_client'));
					} else {
						console.log(res.data)
					}
				}
			})
			.catch((err) => {
				console.log(err, ' err: ', JSON.stringify(obj));
				if (err.response) {
					console.log(err.response);
					dispatch(changeGlobalError('server_error'));
				} else {
					dispatch(changeGlobalError('no_internet'));
				};
			})
			.finally(() => {
				dispatch({ type: SET_SPECIFIC_IS_LOADING, payload: false });
			});
	};
};

export const clearSpecificMessageData = () => {
	return {
		type: CLEAR_SPECIFIC_MESSAGE
	};
};

// Зарегистрировать ЕСИА юзера
export const createNewESIAClient = (vals) => (dispatch, getState) => {
	let config = JSON.parse(localStorage.getItem('conf'));
	const INN = config.code;
	const APP_VERSION = config.app_version;

	const state = getState();

	let obj = {
		ИННОрганизации: INN,
		ВерсияПриложения: APP_VERSION,
		ТипАвторизации: 'ЕСИА',
		МобильныйТелефон: `${vals.phone}`,
		code: state.user.esia.code,
	};

	// console.log(obj);
	return axios
		.post(`${BASE_API_PATH}/create_new_client_esia`, obj, defaultHeader)
		.then((res) => {
			// console.log(res);
			if ((res.data.status && res.data.status === 'error') || (res.data.Status && res.data.Status === 'Error')) {
				dispatch(changeGlobalError('1C_error'));
				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						status: 'error'
					}
				});
			} else {
				// addUserToLocal()
				// Promise.resolve()
        //   .then(() => {
        //     addUserToLocal({ ...res.data.return, phone: vals.phone });
        //   })
        //   .then(() => {
				// 		dispatch(getRequisites());
				// 		dispatch(getLoans());
				// 		dispatch(createSpecificMessage());
        //   });

				dispatch({
					type: ADD_USER_SERVER_RESPONSE,
					payload: {
						...res,
						phone: vals.phone
					}
				});
			}
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
				dispatch(changeGlobalError('server_error'));
			} else {
				dispatch(changeGlobalError('no_internet'));
			}
		});
};
