import React, { memo, useState, useEffect } from 'react';
import './Tabs.less';
import { Button } from 'antd';
import { useSelector } from 'react-redux';

import LoanApplications from './LoanApplications/LoanApplications';
import Loans from './Loans/Loans';
import Home from './Home/Home';
// import CardHome from '../Layout/CardHome/CardHome';
import { Link } from 'react-router-dom';
import SavingApplication from './SavingApplications/SavingApplications';
import Savings from './Savings/Savings';
import StandardContributions from './StandardContributions/StandardContributions';
import RecurringContributions from './RecurringContributions/RecurringContributions';
import Messages from './Messages/Messages';
import Contacts from './Contacts/Contacts';
import LoanModal from '../Modals/LoanModal/LoanModal';
import DepositModalNew from '../Modals/DepositModal_new/DepositModal_new';
import DepositModal from '../Modals/DepositModal/DepositModal';
import MsgModal from '../Modals/MsgModal/MsgModal';
import Calc from './Calc/Calc';
import useWindowSize from '../../../../hooks/useWindowSize';

const initVisible = { loan: false, msg: false, deposit: false };
const TabsHolder = ({ activeTab, isLoading, setActiveTab, sidebarOptions }) => {
  const [visible, setVisible] = useState(initVisible);
  const [initMsg, setInitMsg] = useState({});
  const [newMsg, setNewMsg] = useState(false);
  const config = useSelector((state) => state.config);
  const { width } = useWindowSize();
  const depositSetting = config['1c_configs'].return.НастройкиСбережений.ПодписыватьЗаявки;

  useEffect(() => {
    if (initMsg.UIDСообщения) {
      setVisible((visible) => {
        return { ...visible, msg: true };
      });
    }
  }, [initMsg]);

  return (
    <div className='site-layout-background'>
      {/* {isLoading && <Loader isLoading={isLoading}/>} */}

      <div className='tabs-header'>
        {/* <h1>{activeTab.title}</h1> */}
        {activeTab.id === 'homeCard' ? (
          <h1>{activeTab.title}</h1>
        ) : (
          <h1>
            <Link to='/login' className='__backbutton' title='На главную'></Link>
            {activeTab.title}
          </h1>
        )}
        {activeTab.id === 1 && width > 767 && (
          <Button
            type='primary'
            className='btn-send-loan'
            onClick={() => setVisible({ ...visible, loan: true })}
          >
            Подать заявку
          </Button>
        )}
        {activeTab.id === 3 && width > 767 && (
          <Button
            type='primary'
            className='btn-send-loan'
            onClick={() => setVisible({ ...visible, deposit: true })}
          >
            Подать заявку
          </Button>
        )}
        {activeTab.id === 7 && width > 767 && (
          <Button
            type='primary'
            className='btn-send-message'
            onClick={() => setVisible({ ...visible, msg: true })}
          >
            Написать сообщение
          </Button>
        )}
      </div>
      <div className='tabs-main'>
        {activeTab.id === 'homeCard' && (
          <Home
            isLoading={isLoading}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            sidebarOptions={sidebarOptions}
          />
        )}
        {activeTab.id === 'calcCard' && <Calc />}
        {activeTab.id === 'docCard' && <Calc />}
        {activeTab.id === 'profileCard' && <Calc />}
        {activeTab.id === 1 && <LoanApplications />}
        {activeTab.id === 2 && <Loans />}
        {activeTab.id === 3 && <SavingApplication />}
        {activeTab.id === 4 && <Savings />}
        {activeTab.id === 5 && <StandardContributions />}
        {activeTab.id === 6 && <RecurringContributions />}
        {activeTab.id === 7 && (
          <Messages setInitMsg={setInitMsg} newMsg={newMsg} setNewMsg={setNewMsg} />
        )}
        {activeTab.id === 8 && <Contacts />}
      </div>
      {/* <div className='tabs-footer'>
        <Button type='primary' className='btn-send-loan' onClick={() => setVisible({ ...visible, loan: true })}>Подать заявку</Button>
      </div> */}
      <LoanModal
        visible={visible.loan}
        onCancel={() => {
          setVisible({ ...visible, loan: false });
        }}
      />
      {depositSetting ? (
        <DepositModalNew
          visible={visible.deposit}
          onCancel={() => {
            setVisible({ ...visible, deposit: false });
          }}
        />
      ) : (
        <DepositModal
          visible={visible.deposit}
          onCancel={() => {
            setVisible({ ...visible, deposit: false });
          }}
        />
      )}

      <MsgModal
        visible={visible.msg}
        onCancel={() => {
          setVisible({ ...visible, msg: false });
          setInitMsg({});
        }}
        initMsg={initMsg}
        clearInitMsg={() => setInitMsg({})}
        setNewMsg={setNewMsg}
      />
    </div>
  );
};

export default memo(TabsHolder);
