import React, { memo } from 'react';
import { Form, Input } from 'antd';
import { FileOutlined } from '@ant-design/icons';

const LoanAgreementNumber = () => {
  return (
    <Form.Item
      name='loan_agreement_number'
      label='Номер договора рассрочки'
      rules={[
        {
          required: true,
          message: 'Заполните номер договора рассрочки',
        },
      ]}
    >
      <Input
        prefix={<FileOutlined className='site-form-item-icon' />}
        autoComplete='off'
        autoCorrect='off'
        spellCheck='false'
        allowClear
      />
    </Form.Item>
  );
};

export default memo(LoanAgreementNumber);