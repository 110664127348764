import React, { memo } from 'react';

const Calc = () => {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '2rem 0',
    fontSize: '32px',
  };
  return <span style={style}>В разработке</span>;
};
export default memo(Calc);
