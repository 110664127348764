import React, { memo, useState, useEffect } from 'react';
import { Menu, Dropdown, Popover } from 'antd';
import { DownOutlined, UserOutlined, MenuOutlined } from '@ant-design/icons';
import UserCart from './UserCart';
import useWindowSize from '../../../../../hooks/useWindowSize';
import removeUserFromLocal from '../../../../../heplers/removeUserFromLocal';
import useHistoryNavigation from '../../../../../hooks/useHistoryNavigation';

const UserDropdown = ({ openChangeModal }) => {
  const { goToLogin, Link } = useHistoryNavigation();
  const { width } = useWindowSize();
  const [hello, setHello] = useState(true);
  useEffect(() => {
    setTimeout(() => setHello(false), 30000);
  }, []);

  const content = (
    <div>
      <p>Извините, функция ещё в разработке</p>
    </div>
  );
  const menu = (
    <Menu>
      {/* {width <= 450 && (
        <p className='user-title'>
          <UserOutlined /> {JSON.parse(localStorage.getItem('user'))}
        </p>
      )} */}
      {width <= 450 && <Menu.Divider />}
      <Menu.Item key='2' onClick={openChangeModal}>
        Изменить пароль
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item
        key='3'
        onClick={() => {
          removeUserFromLocal();
          goToLogin();
        }}
      >
        Выйти
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={width <= 767 ? 'main-header-wrapper-mobile' : 'main-header-wrapper'}
      style={hello ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}
    >
      {width <= 767 && hello && (
        <>
          <Link
            className='ant-dropdown-link main-header-user'
            to='/'
            onClick={(e) => e.preventDefault()}
          >
            <span className='__username'>
              <b>Добро пожаловать </b>
              <i>{JSON.parse(localStorage.getItem('user'))}</i>
            </span>
          </Link>
        </>
      )}

      {width > 767 && (
        <>
          <Dropdown overlay={menu} trigger={['click']}>
            <Link
              className='ant-dropdown-link main-header-user'
              to='/'
              onClick={(e) => e.preventDefault()}
            >
              <span className='__username'>
                <b>{width > 340 ? 'Добро пожаловать ' : ''}</b>
                <i>{width > 340 ? JSON.parse(localStorage.getItem('user')) : ''}</i>
                {width > 767 ? <DownOutlined /> : <></>}
              </span>
            </Link>
          </Dropdown>
        </>
      )}

      <div
        className={width <= 767 ? 'main-header-wrapper-mobile-extra' : 'main-header-wrapper-extra'}
      >
        {width <= 767 ? (
          <Popover placement='bottomRight' content={content}>
            <span className='__support'></span>
          </Popover>
        ) : (
          ''
        )}
        {width <= 767 ? <UserCart /> : <></>}
      </div>
    </div>
  );
};

export default memo(UserDropdown);

