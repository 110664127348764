import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
	createLoan,
	clearUserServerResponse,
	getUserData,
	clearSpecificMessageData
} from '../../../../../redux/ducks/user';
import { changeMsgWork } from '../../../../../redux/ducks/messages';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';

import LoanForm from '../../../../../common/components/Forms/LoanForm/LoanForm';
import LoanAcceptSmsModal from '../../../../../common/components/Modals/LoanAcceptSmsModal/LoanAcceptSmsModal';

const initSliderValues = { first: 0, second: 0 };

const LoanModal = ({ visible, onCancel }) => {
	const [ form ] = Form.useForm();
	const [ isLoading, changeIsLoading ] = useState(false);
	const [ disabled, setDisabled ] = useState(true);
	const [ loanType, changeLoanType ] = useState({});
  const [ sliderValues, setSliderValues ] = useState(initSliderValues);
  const [acceptSmsModal, setAcceptSmsModal] = useState(false);
  const [initClean, setInitClean] = useState(false);

	const serverResponse = useSelector((state) => state.user.serverResponse);
  const requisites = useSelector((state) => state.user.credit.requisites);
  const specificMessage = useSelector((state) => state.user.credit.specificMessage);

	const dispatch = useDispatch();

	const getRequsitesValues = (values) => {
		let a = [];
		requisites.Реквизит &&
			requisites.Реквизит.map((el) => {
				let obj = el;
				obj.Значение =
					el.ВнутреннийТип === 'Дата рождения'
						? makeSimpleData(values['Дата рождения'])
						: values[el.Наименование] === undefined ? undefined : values[el.Наименование];
				if (el.ВнутреннийТип === 'Произвольный реквизит (булево)' && el.Обязателен) {
					obj.Значение = false;
				}
				if (obj.Значение !== undefined) {
					delete obj.Представление;
					delete obj.НомерСтроки;
					a.push(obj);
				}
				return obj;
			});
		return a;
	};

	const onCreate = (values) => {
		let obj = {
			...values,
			ЕдИзмСрока: loanType.ЕдИзмСрока,
			СписокЗначенийРеквизитов: getRequsitesValues(values),
      ТипВидЗайма: loanType,
      UIDСообщения: specificMessage.UIDСообщения,
		};
		if (!obj.СписокЗначенийРеквизитов.length) {
			delete obj.СписокЗначенийРеквизитов;
		}
		// console.log(obj)
		changeIsLoading(true);
		dispatch(createLoan(obj));
	};

	const clearModal = useCallback(
		() => {
			setSliderValues(initSliderValues);
			setDisabled(true);
			changeLoanType({});
      form.resetFields();
		},
		[ form ]
	);

	const closeConfirm = () => {
		let values = form.getFieldsValue();
		// console.log(JSON.stringify(values));
		if (JSON.stringify(values) === '{}') {
			onCancel();
		} else {
			Modal.confirm({
				// title: 'Confirm',
				icon: <ExclamationCircleOutlined />,
				content:
					'Вы действительно хотите прервать процесс подачи заявки на рассрочку? Введенные данные не сохранятся.',
				okText: 'Да',
				cancelText: 'Нет',
				centered: true,
				onOk: () => {
					onCancel();
          if(specificMessage.UIDСообщения) {
            dispatch(clearSpecificMessageData());
          };
					setTimeout(() => {
						clearModal();
					}, 100);
				}
			});
		}
	};

	const loanErrorConfirm = useCallback((err) => {
		Modal.error({
			title: 'Ошибка!',
			content: err,
			onOk: () => {}
		});
	}, []);

	const loanSuccessConfirm = useCallback(
		(msg) => {
			Modal.success({
				title: 'Готово!',
				content: msg ? msg : 'Ваша заявка успешно отправленна! Ожидайте звонка специалиста.',
				centered: true,
				onOk: () => {
					onCancel();
					dispatch(getUserData());
					dispatch(changeMsgWork(false));
					dispatch(clearSpecificMessageData());
					setTimeout(() => {
						clearModal();
					}, 100);
				}
			});
		},
		[ onCancel, clearModal, dispatch ]
	);

	useEffect(
		() => {
			if (serverResponse && serverResponse.status === 200 && isLoading) {
        changeIsLoading(false);
        // закрываем модалку подписи через смс
        setAcceptSmsModal(false);
        // запускаем очистку всех форм в модалке подписи через смс
        setInitClean(true);
				if (serverResponse.data.return.КодОшибки && serverResponse.data.return.КодОшибки === '0') {
          // показываем сообщение, что все ок, заявка создана
					loanSuccessConfirm(serverResponse.data.return.ОписаниеОшибки);
				} else {
          // показываем сообщение, что все не ок и что именно не ок
					loanErrorConfirm(serverResponse.data.return ? serverResponse.data.return.ОписаниеОшибки : '');
				}
				dispatch(clearUserServerResponse());
			};
			if (serverResponse && serverResponse.status === 'error' && isLoading) {
				changeIsLoading(false);
				dispatch(clearUserServerResponse());
			};
		},
		[ serverResponse, loanSuccessConfirm, loanErrorConfirm, isLoading, dispatch ]
  );

  const onSuccessSmsAccept = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

	return (
    <>
      <Modal
        visible={visible}
        title="Заявка на рассрочку"
        okText="Отправить заявку на рассмотрение"
        cancelText="Выйти"
        onCancel={closeConfirm}
        onOk={() => {
          form
            .validateFields()
            .then(() => {
              setAcceptSmsModal(true)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        okButtonProps={{ disabled: disabled }}
        className="change-password-modal"
        centered
        // бублик у нас теперь тут не крутится, удалять не буду
        // confirmLoading={isLoading}
      >
        <LoanForm
          form={form}
          setDisabled={setDisabled}
          sliderValues={sliderValues}
          setSliderValues={setSliderValues}
          isLoading={isLoading}
          loanType={loanType}
          changeLoanType={changeLoanType}
        />
      </Modal>
      {/* модалка подписи сообщения через смс */}
      <LoanAcceptSmsModal
        onSuccessSmsAccept={onSuccessSmsAccept}
        visible={acceptSmsModal}
        onCancel={() => setAcceptSmsModal(false)}
        flags={
          { ПодписьЧерезСМС: specificMessage.ПодписьЧерезСМС }
        }
        UIDСообщения={specificMessage.UIDСообщения}
        initClean={
          {
            initClean: initClean,
            setInitClean: setInitClean
          }
				}
				okText='Отправить заявку на рассрочку'
      />
    </>
	);
};

export default memo(LoanModal);
