import React, { memo, useState, useEffect } from 'react';
import { Table, Card, Button, message } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from '../../../../../hooks/useWindowSize';

import OpenCard from '../../TableCards/LoansCard/OpenCard';
import FileModal from '../../Modals/FileModal/FileModal';

import makeNumberPretty from '../../../../../heplers/makeNumberPretty';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';
import {
  getLoanReports,
  saveLoanReport,
  clearUserServerResponse,
} from '../../../../../redux/ducks/user';

const initVisible = { is: false, title: '', uid: '', type: '' };

const Loans = () => {
  const [expandedRowKeys, changeExpandedRowKeys] = useState([]);
  const [openCard, setOpenCard] = useState([]);
  const [visible, setVisible] = useState(initVisible);
  const [tableData, changeTableData] = useState([]);
  const [isPersonal, setIsPersonal] = useState(false);

  const userData = useSelector((state) => state.user.data);
  const serverResponse = useSelector((state) => state.user.serverResponse);

  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible.uid) {
      let vals = {
        UIDДоговора: visible.uid,
        [visible.type]: true,
        key: visible.type,
      };
      if (visible.is) {
        dispatch(getLoanReports(vals));
      } else {
        dispatch(saveLoanReport(vals));
      };
    }
  }, [visible.is, dispatch, visible.uid, visible.type]);

  useEffect(() => {
    if (
      userData &&
      userData.ИнформацияОЗаймах &&
      userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма
    ) {
      let arr = []
      if (Array.isArray(userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма)) {
        arr = userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма
      } else {
        arr.push(userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма);
      };
      let isPersonal = arr.find(el => el.ЛицевойСчет)
      if(isPersonal) {
        setIsPersonal(true);
      };
      changeTableData(arr);
    }
  }, [userData]);

  useEffect(() => {
    if (serverResponse && serverResponse.status === 'success') {
      dispatch(clearUserServerResponse());
      setVisible(initVisible);
    }
  }, [serverResponse, dispatch]);

  const onOpenCard = (id) => {
    if (openCard.includes(id)) {
      let a = openCard.filter((el) => el !== id);
      setOpenCard(a);
    } else {
      let a = [...openCard, id];
      setOpenCard(a);
    }
  };

  const getPayment = (record) => {
    let a =
      +record.тПлатеж.Номинал +
      +record.тПлатеж.Комиссия +
      +record.тПлатеж.Пени +
      +record.тПлатеж.ПениКомиссия +
      +record.тПлатеж.Проценты;
    // +(+record.тПлатеж.Проценты)+(+record.тПлатеж.Комиссия)+(+record.тПлатеж.Пенни)+(+record.тПлатеж.ПениКомиссия));
    // console.log(a)
    return (
      <p className='table-number'>
        {makeNumberPretty(a)} руб. <br />
        {record.СтатусПредставление.split(' ')[1] === 'просрочке' && (
          <>
            <span className='text-fail'>просроченный платеж</span> <br />
          </>
        )}
        <span className='text-secondary'> до {makeSimpleData(record.ДатаПлатежа)}</span>
      </p>
    );
  };

  let columns = [
    {
      title: 'Договор',
      key: 'number',
      responsive: ['md'],
      align: 'center',
      render: (text, record) => (
        <p className='table-number'>
          {record.НомерДоговора} <br />
          <span className='text-secondary'>от {makeSimpleData(record.ДатаДоговора)}</span>
        </p>
      ),
    },
    {
      title: 'Лицевой счет',
      key: 'personal',
      responsive: ['md'],
      align: 'center',
      render: (text, record) => (
        <Button
          type='link'
          onClick={() => copyPersonal(record.ЛицевойСчет)}
        >
          {record.ЛицевойСчет}
        </Button>
      ),
    },
    {
      title: 'Статус',
      key: 'status',
      responsive: ['md'],
      align: 'center',
      render: (text, record) => (
        // <></>
        <p
          className={
            record.СтатусПредставление === '0'
              ? 'table-number'
              : record.СтатусПредставление === '1'
              ? 'text-fail'
              : record.СтатусПредставление.split(' ')[1] === 'просрочке'
              ? 'text-fail'
              : 'table-number'
          }
        >
          {record.СтатусПредставление === '0'
            ? 'В норме'
            : record.СтатусПредставлени === '1'
            ? 'Ошибка расчета'
            : record.СтатусПредставление}
        </p>
      ),
    },
    {
      title: 'Платежи по графику',
      // dataIndex: 'sum',
      key: 'sum',
      responsive: ['md'],
      align: 'center',
      render: (text, record) => getPayment(record),
    },
    {
      title: '',
      key: 'actions',
      // dataIndex: 'tags',
      responsive: ['md'],
      align: 'center',
      render: (text, record) => (
        <>
          <Button
            type='link'
            className='register-btn'
            onClick={() => {
              if (expandedRowKeys.includes(record.UIDДоговора)) {
                onTableRowExpand(false, record);
              } else {
                onTableRowExpand(true, record);
              }
            }}
          >
            Подробнее
          </Button>
        </>
      ),
    },
  ];

  const onTableRowExpand = (expanded, article) => {
    let keys = expandedRowKeys;
    let a = [];
    if (expanded) {
      a = [...keys, article.UIDДоговора];
    };

    if (!expanded) {
      a = keys.filter((el) => el !== article.UIDДоговора);
    };
    changeExpandedRowKeys(a);
  };

  const copyPersonal = (personal) => {
    navigator.clipboard.writeText(personal);
    if (window.flutter_inappwebview){
      window.flutter_inappwebview.callHandler('copyToClipboard', personal);
    }
    setTimeout(() => {
      message.info('Личный счет скопирован в буфер обмена');
    }, 100);
  };

  return (
    <>
      {tableData.length ? (
        width >= (isPersonal ? 970 : 852) ? (
            <Table
              className='table-width'
              rowKey={(record) => record.UIDДоговора}
              columns={isPersonal ? columns : columns.filter(el => el.key !== 'personal')}
              dataSource={tableData}
              pagination={false}
              scroll={{ y: 'calc(100vh - 64px - 65.8px - 54.4px - 5rem)' }}
              expandable={{
                expandedRowRender: (record) => (
                  <OpenCard record={record} setVisible={setVisible} colNum={12} />
                ),
                expandedRowKeys: expandedRowKeys,
                onExpand: (a, b) => onTableRowExpand(a, b),
                expandIcons: null,
              }}
            />
        ) : (
          <div
            className='card-wrapper'
          >
            {tableData.map((el) => {
              return (
                <Card key={el.UIDДоговора} className='card-holder'>
                  <div className='card-row'>
                    <span className='card-title'>Договор</span>
                    <p className='table-number'>
                      {el.НомерДоговора} <br />
                      <span className='text-secondary'>от {makeSimpleData(el.ДатаДоговора)}</span>
                    </p>
                  </div>
                  {isPersonal ?
                    <div className='card-row'>
                      <span className='card-title'>
                        Лицевой счет
                      </span>
                      <p className='table-number'>
                      <Button
                        type='link'
                        onClick={() => copyPersonal(el.ЛицевойСчет)}
                      >
                        {el.ЛицевойСчет}
                      </Button>
                      </p>
                    </div>
                  : <></>
                  }
                  <div className='card-row'>
                    <span className='card-title'>Статус</span>
                    <p
                      className={
                        el.СтатусПредставление === '0'
                          ? 'table-number'
                          : el.СтатусПредставление === '1'
                          ? 'text-fail'
                          : el.СтатусПредставление.split(' ')[1] === 'просрочке'
                          ? 'text-fail'
                          : 'table-number'
                      }
                    >
                      {el.СтатусПредставление === '0'
                        ? 'В норме'
                        : el.СтатусПредставлени === '1'
                        ? 'Ошибка расчета'
                        : el.СтатусПредставление}
                    </p>
                  </div>
                  <div className='card-row'>
                    <span className='card-title'>Рекомендованный платеж</span>
                    {getPayment(el)}
                  </div>

                  <Button
                    type='primary'
                    className='tabs-card-btn'
                    onClick={() => onOpenCard(el.UIDДоговора)}
                  >
                    Подробнее
                  </Button>

                  <div
                    className={
                      openCard.includes(el.UIDДоговора)
                        ? 'loan-card-holder visible'
                        : ' loan-card-holder invisible'
                    }
                  >
                    <OpenCard record={el} setVisible={setVisible} colNum={24} />
                  </div>
                </Card>
              );
            })}
          </div>
        )
      ) : (
        <p className='no-data'> У Вас нет рассрочек</p>
      )}
      <FileModal onClose={() => setVisible(initVisible)} visible={visible} />
    </>
  );
};

export default memo(Loans);
