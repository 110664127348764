import React, { memo, useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import useWindowSize from '../../../../../hooks/useWindowSize';

const { Sider } = Layout;

const LeftSidebar = ({
  activeTab,
  setActiveTab,
  mainHeight,
  sidebarOptions,
  setSidebarOptions,
}) => {
  const { width } = useWindowSize();
  const [collapsed, setCollapsed] = useState(width < 768);
  // const [sidebarOptions, setSidebarOptions] = useState([]);
  // const config = useSelector(state => state.config);

  // useEffect(() => {
  //   const mainSettings = config['1c_configs'].return
  //     .ГлавныеНастройки;

  //   if (config) {
  //     setSidebarOptions([
  //       {
  //         id: 'homeCard',
  //         title: 'Главная',
  //         text: 'Документы',
  //         show: true,
  //       },
  //       {
  //         id: 1,
  //         title: 'Заявки на рассрочку',
  //         show: mainSettings['ОтображатьЗаявкиНаЗайм'],
  //       },
  //       {
  //         id: 2,
  //         title: 'Договора рассрочки',
  //         show: mainSettings['ОтображатьЗаймы'],
  //       },
  //       {
  //         id: 3,
  //         title: 'Заявки на сбережения',
  //         show: mainSettings['ОтображатьЗаявкиНаСбережения'],
  //       },
  //       {
  //         id: 4,
  //         title: 'Сбережения',
  //         show: mainSettings['ОтображатьСбережения'],
  //       },
  //       {
  //         id: 5,
  //         title: 'Стандартные взносы',
  //         show: mainSettings['ОтображатьСтандартныеВзносы'],
  //       },
  //       {
  //         id: 6,
  //         title: 'Периодические взносы',
  //         show: mainSettings['ОтображатьПериодическиеВзносы'],
  //       },
  //       {
  //         id: 7,
  //         title: 'Сообщения',
  //         // show: true,
  //         show: mainSettings['ОтображатьСообщения'],
  //       },
  //       {
  //         id: 8,
  //         title: 'Контакты ',
  //         icon: 1,
  //         // show: true
  //       },
  //     ]);
  //   }
  // }, [config]);

  // useEffect(() => {
  //   if (sidebarOptions.length) {
  //     let a = sidebarOptions.find((el) => el.show);
  //     setActiveTab(a);
  //   }
  // }, [setActiveTab, sidebarOptions]);

  return (
    <Sider
      breakpoint='md'
      collapsedWidth='0'
      collapsed={collapsed}
      // onBreakpoint={(broken) => {
      //   console.log(broken);
      // }}
      onCollapse={(collapsed, type) => {
        // console.log(collapsed, type);
        setCollapsed(collapsed);
      }}
      style={
        width < 768
          ? {
              position: 'absolute',
              zIndex: '2',
              minHeight: `${mainHeight}px`,
            }
          : {}
      }
    >
      <Menu
        theme='dark'
        mode='inline'
        defaultSelectedKeys={['1']}
        selectedKeys={[`${activeTab.id}`]}
      >
        {sidebarOptions.length > 0 &&
          sidebarOptions
            .filter((el) => el.show)
            .map((el) => (
              <Menu.Item
                key={el.id}
                onClick={() => {
                  setActiveTab(el);
                  if (width < 768) {
                    setCollapsed(true);
                  }
                }}
              >
                {el.title}
                {el.icon && <FormOutlined />}
              </Menu.Item>
            ))}
      </Menu>
    </Sider>
  );
};

export default memo(LeftSidebar);

