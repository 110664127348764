import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, Form, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { createLoan, clearUserServerResponse, getUserData, clearEsia } from '../../../../../redux/ducks/user';
import { changeMsgWork } from '../../../../../redux/ducks/messages';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';

import LoanForm from '../../../../../common/components/Forms/LoanForm/LoanForm';
import LoanAcceptSmsModal from '../../../../../common/components/Modals/LoanAcceptSmsModal/LoanAcceptSmsModal';
import useHistoryNavigation from '../../../../../hooks/useHistoryNavigation';

const initSliderValues = { first: 0, second: 0 };

const ESIALoanForm = () => {
  const [form] = Form.useForm();
  const [isLoading, changeIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loanType, changeLoanType] = useState({});
  const [sliderValues, setSliderValues] = useState(initSliderValues);
  const [acceptSmsModal, setAcceptSmsModal] = useState(false);
  const [initClean, setInitClean] = useState(false);

  const serverResponse = useSelector((state) => state.user.serverResponse);
  const requisites = useSelector((state) => state.user.credit.requisites);
  const specificMessage = useSelector((state) => state.user.credit.specificMessage);

  const dispatch = useDispatch();
  const { goToMain } = useHistoryNavigation();

  useEffect(() => {
    if (specificMessage.UIDСообщения) {
      setDisabled(false);
    }
  }, [specificMessage.UIDСообщения]);

  const getRequsitesValues = (values) => {
    let a = [];
    requisites.Реквизит &&
      requisites.Реквизит.map((el) => {
        let obj = el;
        obj.Значение =
          el.ВнутреннийТип === 'Дата рождения'
            ? makeSimpleData(values['Дата рождения'])
            : values[el.Наименование] === undefined
            ? undefined
            : values[el.Наименование];
        if (el.ВнутреннийТип === 'Произвольный реквизит (булево)' && el.Обязателен) {
          obj.Значение = false;
        }
        if (obj.Значение !== undefined) {
          delete obj.Представление;
          delete obj.НомерСтроки;
          a.push(obj);
        }
        return obj;
      });
    return a;
  };

  const onCreate = (values) => {
    let obj = {
      ...values,
      ЕдИзмСрока: loanType.ЕдИзмСрока,
      СписокЗначенийРеквизитов: getRequsitesValues(values),
      ТипВидЗайма: loanType,
      UIDСообщения: specificMessage.UIDСообщения,
    };
    if (!obj.СписокЗначенийРеквизитов.length) {
      delete obj.СписокЗначенийРеквизитов;
    };
    // console.log(obj)
    changeIsLoading(true);
    dispatch(createLoan(obj));
  };

  const loanErrorConfirm = useCallback((err) => {
    Modal.error({
      title: 'Ошибка!',
      content: err,
      onOk: () => {},
    });
  }, []);

  const loanSuccessConfirm = useCallback(
    (msg) => {
      Modal.success({
        title: 'Готово!',
        content: msg ? msg : 'Ваша заявка успешно отправленна! Ожидайте звонка специалиста.',
        centered: true,
        onOk: () => {
          dispatch(getUserData());
          dispatch(changeMsgWork(false));
          dispatch(clearEsia());
          setTimeout(() => {
            goToMain();
          }, 100);
        },
      });
    },
    [dispatch, goToMain],
  );

  useEffect(() => {
    if (serverResponse && serverResponse.status === 200 && isLoading) {
      changeIsLoading(false);
      // закрываем модалку подписи через смс
      setAcceptSmsModal(false);
      // запускаем очистку всех форм в модалке подписи через смс
      setInitClean(true);
      if (serverResponse.data.return.КодОшибки && serverResponse.data.return.КодОшибки === '0') {
        // показываем сообщение, что все ок, заявка создана
        loanSuccessConfirm(serverResponse.data.return.ОписаниеОшибки);
      } else {
        // показываем сообщение, что все не ок и что именно не ок
        loanErrorConfirm(
          serverResponse.data.return ? serverResponse.data.return.ОписаниеОшибки : '',
        );
      }
      dispatch(clearUserServerResponse());
    }
    if (serverResponse && serverResponse.status === 'error' && isLoading) {
      changeIsLoading(false);
      dispatch(clearUserServerResponse());
    }
  }, [serverResponse, loanSuccessConfirm, loanErrorConfirm, isLoading, dispatch]);

  const onSuccessSmsAccept = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };
  return (
    <>
      {!disabled && (
        <LoanForm
          form={form}
          setDisabled={setDisabled}
          sliderValues={sliderValues}
          setSliderValues={setSliderValues}
          isLoading={isLoading}
          loanType={loanType}
          changeLoanType={changeLoanType}
          needHideLoanCheckbox={true}
        />
      )}
      <Button
        type='primary'
        disabled={disabled}
        onClick={() => {
          form
            .validateFields()
            .then(() => {
              setAcceptSmsModal(true);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        Отправить заявку на рассмотрение
      </Button>
      <LoanAcceptSmsModal
        onSuccessSmsAccept={onSuccessSmsAccept}
        visible={acceptSmsModal}
        onCancel={() => setAcceptSmsModal(false)}
        flags={{ ПодписьЧерезСМС: specificMessage.ПодписьЧерезСМС }}
        UIDСообщения={specificMessage.UIDСообщения}
        initClean={{
          initClean: initClean,
          setInitClean: setInitClean,
        }}
        okText='Отправить заявку на рассрочку'
        skipSmsTimeout
      />
    </>
  );
};

export default memo(ESIALoanForm);
