import React, { memo, useEffect, useState } from 'react';
import { Form, Slider, Input, Checkbox, Select, Switch, InputNumber, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import makeNumberPretty from '../../../../../../heplers/makeNumberPretty';

const { TextArea } = Input;
const { Option } = Select;

const LoanForm = ({
  form,
  setDisabled,
  sliderValues,
  setSliderValues,
  isLoading,
  depositType,
  changeDepositType,
}) => {
  const deposits = useSelector((state) => state.user.deposit.types);
  const requisites = useSelector((state) => state.user.deposit.requisites);
  const [ fileList, setFileList ] = useState([]);
  const config = useSelector((state) => state.config);

	const props = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			let a = [ ...fileList, file ];
			setFileList(a);
			return false;
		},
		fileList
	};

	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

  useEffect(() => {
    if (sliderValues.first) {
      let c = depositType.СуммаМакс - depositType.СуммаМин;
      let d = c / 100;
      let h = sliderValues.first * d;
      let a = h + +depositType.СуммаМин;
      // console.log(a);
      let g = Math.round(a / 1000) * 1000;
      // let b = makeNumberPretty(g);
      form.setFieldsValue({ Сумма: g });
    } else {
      form.setFieldsValue({ Сумма: makeNumberPretty(depositType.СуммаМин) });
    }
  }, [sliderValues.first, depositType, form]);

  useEffect(() => {
    // console.log(sliderValues.second)
    if (sliderValues.second) {
      let a =
        sliderValues.second * ((depositType.СрокМакс - depositType.СрокМин) / 100) + +depositType.СрокМин;
      form.setFieldsValue({ Срок: Math.ceil(a) });
    } else {
      form.setFieldsValue({ Срок: depositType.СрокМин });
    }
  }, [sliderValues.second, depositType, form]);

  const marks = {
    0: depositType.СуммаМин ? makeNumberPretty(depositType.СуммаМин) : '0',
    100: {
      style: {
        whiteSpace: 'nowrap',
      },
      label: <>{depositType.СуммаМакс ? makeNumberPretty(depositType.СуммаМакс) : '100'}</>,
    },
  };

  const marks2 = {
    0: depositType.СрокМин ? `${depositType.СрокМин} ${depositType.ЕдИзмСрока}` :  `1 ${depositType.ЕдИзмСрока}`,
    100: {
      style: {
        whiteSpace: 'nowrap',
      },
      label: <>{depositType.СрокМакс ? `${depositType.СрокМакс} ${depositType.ЕдИзмСрока}` : `12 ${depositType.ЕдИзмСрока}`}</>,
    },
  };

  return (
    <>
      <Form
        form={form}
        layout='vertical' // "horizontal"
        name='loan_modal'
        initialValues={{
          ЕдИзмСрока: depositType.ЕдИзмСрока,
        }}
        size={'middle'}
      >
        <Form.Item name='ВидСбережения' label='Вид сбережения'>
          <Select
            placeholder='Выберите вид сбережения'
            onSelect={(a, b) => {
              changeDepositType(b['data-el']);
              setSliderValues({ first: 0, second: 0 });
            }}
          >
            {deposits.Сбережение &&
            Array.isArray(deposits.Сбережение) ?
              deposits.Сбережение.map((el, ind) => (
                <Option key={el.Код+ind} value={el.НаименованиеДляПечати || el.Наименование} data-el={el}>
                  {el.НаименованиеДляПечати || el.Наименование}
                </Option>
              )) :
              <Option key={deposits.Сбережение.Код} value={deposits.Сбережение.НаименованиеДляПечати || deposits.Сбережение.Наименование} data-el={deposits.Сбережение}>
                {deposits.Сбережение.НаименованиеДляПечати || deposits.Сбережение.Наименование}
              </Option>
            }
          </Select>
        </Form.Item>
        {depositType.UIDСбережения && (
          <>
            {/* Какая сумма Вам нужна? */}
            <div className='loan-input-group'>
              <Form.Item name='ЕдИзмСрока' className='loan-none'>
                <Input autoComplete='off' autoCorrect='off' spellCheck='false' readOnly />
              </Form.Item>
              <div className='loan-input-item'>
                <p>Какую сумму Вы планируете разместить?</p>
                <Form.Item name='Сумма' className='loan-input'>
                  <InputNumber
                    suffix='руб.'
                    min={depositType.СуммаМин}
                    max={depositType.СуммаМакс}
                    formatter={(value) =>
                      `${value} руб.`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
                    }
                    parser={(value) => value.replace(/(\s*)руб.?|(\s*)/g, '')}
                    onChange={(val) => {
                      if (val) {
                        let c = depositType.СуммаМакс - depositType.СуммаМин;
                        let d = c / 100;
                        let g = (val - depositType.СуммаМин) / d;
                        setSliderValues({ ...sliderValues, first: g });
                      } else {
                        form.setFieldsValue({ Сумма: depositType.СуммаМин });
                        setSliderValues({ ...sliderValues, first: 0 });
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <Slider
                onChange={(val) => {
                  setSliderValues({ ...sliderValues, first: val });
                }}
                defaultValue={0}
                step={0.1}
                marks={marks}
                tooltipVisible={false}
                value={sliderValues.first}
                disabled={isLoading}
              />
            </div>

            {/* На какой период? */}
            <div className='loan-input-group loan-input-group-month'>
              <div className='loan-input-item'>
                <p>На какой период?</p>
                <Form.Item
                  name='Срок'
                  className='loan-input'
                >
                  <InputNumber
                    min={depositType.СрокМин}
                    max={depositType.СрокМакс}
                    formatter={(value) => `${value} ${depositType.ЕдИзмСрока}`}
                    // parser={(value) => depositType.ЕдИзмСрока === 'мес.' ? value.replace(/(\s*)мес./g, '') : value.replace(/(\s*)дн./g, '')}
                    parser={(value) => value.replace(new RegExp(`(\\s*)${depositType.ЕдИзмСрока}`, 'g'), '')}
                    onChange={(val) => {
                      if(val){
                        let c = depositType.СрокМакс - depositType.СрокМин;
                        let d = c / 100;
                        let g = (val - depositType.СрокМин) / d;
                        setSliderValues({ ...sliderValues, second: g });
                      } else {
                        form.setFieldsValue({ Срок: depositType.СрокМин });
                        setSliderValues({ ...sliderValues, second: 0 });
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <Slider
                onChange={(val) => {
                  setSliderValues({ ...sliderValues, second: val });
                }}
                defaultValue={0}
                step={(depositType.СрокМакс - depositType.СрокМин)/100}
                marks={marks2}
                tooltipVisible={false}
                value={sliderValues.second}
                disabled={isLoading}
              />
            </div>

            {/* Динамические данные из реквизитов */}
            {requisites &&
              requisites.Реквизит &&
              requisites.Реквизит.map((el) => {
                if (el.ВнутреннийТип === 'Дата рождения') {
                  return (
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      rules={el.Обязателен && [
                        {
                          required: true,
                          message: `Заполните поле`,
                        }
                      ]}
                    >
                      <DatePicker locale={locale} format='DD.MM.YYYY'/>
                    </Form.Item>
                  );
                } else if (el.ВнутреннийТип === 'Мобильный телефон') {
                  return (
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      rules={[
                        el.Обязателен ? {
                          required: true,
                          message: 'Заполните номер мобильного телефона',
                        } : {},
                        { max: 10, message: 'Номер слишком длинный' },
                        { min: 10, message: 'Номер слишком короткий' },
                      ]}
                      // hasFeedback
                    >
                      <Input
                        addonBefore='+7'
                        type='number'
                        autoComplete='off'
                        autoCorrect='off'
                        spellCheck='false'
                        allowClear
                      />
                    </Form.Item>
                  );
                } else if (el.ВнутреннийТип === 'Произвольный реквизит (булево)') {
                  return (
                    // <Form.Item
                    //   key={el.UIDРеквизита}
                    //   name={el.Наименование}
                    //   label={el.Наименование}
                    //   valuePropName='checked'
                    //   {...tailLayout}
                    // >
                    // <Checkbox readOnly={isLoading} />
                    // </Form.Item>
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                  );
                } else {
                  // console.log(el.ВнутреннийТип);
                  return (
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      rules={el.Обязателен && [
                        {
                          required: true,
                          message: `Заполните поле`,
                        }
                      ]}
                    >
                      <Input
                        type={
                          el.ВнутреннийТип === 'Произвольный реквизит (число)'
                            ? 'number'
                            : el.ВнутреннийТип === 'Произвольный реквизит (строка)'
                            ? 'string'
                            : el.ВнутреннийТип === 'СНИЛС'
                            ? 'string'
                            : 'string'
                        }
                        autoComplete='off'
                        autoCorrect='off'
                        spellCheck='false'
                        allowClear
                        // onChange={(val) => console.log(val)}
                      />
                    </Form.Item>
                  );
                }
              })}

            {/* Загрузка файлов */}
            {config['1c_configs'].return.НастройкиЗаявокНаСбережение.ОтображатьПрисоединениеФайлов && 
              <label style={{ display: 'block' }} htmlFor="msg_modal_upload">
                <Form.Item
                  name="upload"
                  // label="Upload"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  // extra="longggggggggggggggggggggggggggqgggggggg"
                >
                  <Upload
                    name="logo"
                    listType="text"
                    multiple={true}
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, .doc, .docx, .odt, .pdf, .xls, .xlsx, .ods, .numbers"
                    {...props}
                  >
                    <Button disabled={isLoading}>
                      <UploadOutlined /> Прикрепить файлы
                    </Button>
                  </Upload>
                </Form.Item>
              </label>
            }

            {/* Комментарий */}
            <div className='loan-comment'>
              <p>Комментарий</p>
              <Form.Item name='Описание' className='loan-input'>
                <TextArea rows={4} readOnly={isLoading} />
              </Form.Item>
            </div>

            {/* Подтверждение */}
            <div className='loan-input-item loan-input-item__start'>
              <Form.Item name='remember' valuePropName='checked'>
                <Checkbox
                  readOnly={isLoading}
                  onChange={(e) => {
                    setDisabled(!e.target.checked);
                  }}
                  className='loan-input-item__start-check'
                >
                  <p>
                    <span className='sms-error'>*</span> Нажимая кнопку "Отправить заявку на
                    рассмотрение" я даю согласие на обработку персональных данных, с тарифами и условиями выдачи сбережений ознакомлен и
                    согласен.
                  </p>
                </Checkbox>
              </Form.Item>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default memo(LoanForm);
