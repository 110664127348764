import React, { memo, useState, useEffect } from 'react';
import './MainPage.less';
import logo from '../../assets/images/main-logo.svg';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Layout, notification, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import LoanModal from './components/Modals/LoanModal/LoanModal';
import Loader from '../../common/components/Loader/Loader';
import UserDropdown from './components/Layout/UserDropdown/UserDropdown';

import LeftSidebar from './components/Layout/LeftSidebar/LeftSidebar';
// import CardHome from './components/Layout/CardHome/CardHome';
import ChangePasswordModal from './components/Modals/ChangePasswordModal/ChangePasswordModal';
import TabsHolder from './components/Tabs/TabsHolder';

import useWindowSize from '../../hooks/useWindowSize';
import {
  getUserData,
  getRequisites,
  getLoans,
  getDepositTypes,
  getDepositRequisites,
} from '../../redux/ducks/user';
import { sendPaymentStatus, clearPaymentServerResponse } from '../../redux/ducks/payment';
import useHistoryNavigation from '../../hooks/useHistoryNavigation';
import IconHome from './icons/iconHome';
import IconCalc from './icons/iconCalc';
import IconDoc from './icons/iconDoc';
import IconUser from './icons/iconUser';

const { Header, Content, Footer } = Layout;

const BASE_API_PATH = process.env.REACT_APP_API_URL;

const initTab = { id: 'homeCard', title: 'Главная' };
const calcTab = { id: 'calcCard', title: 'Калькулятор' };
const docTab = { id: 'docCard', title: 'Документы' };
const profileTab = { id: 'profileCard', title: 'Личный кабинет' };
const initVisible = { is: false, title: '', uid: '', type: '' };

const MainPage = () => {
  const [visible, setVisible] = useState(false);
  const [visibleLoan, setVisibleLoan] = useState(initVisible);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [mainHeight, setMainHeight] = useState(0);
  const [activeTab, setActiveTab] = useState(initTab);
  const { width } = useWindowSize();

  const userData = useSelector((state) => state.user.data);
  const serverResponse = useSelector((state) => state.user.serverResponse);
  const paymentServerResponse = useSelector((state) => state.payment.serverResponse);

  const { height } = useWindowSize();
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config);
  const fileIsLoading = useSelector((state) => state.messages.fileIsLoading);

  const { goToLogin, clearUrl, history } = useHistoryNavigation();

  // При загрузке главной страницы получаем все данные юзера (Заявки на займы, Займы, Сбережения, Взносы),
  // получаем реквизиты и типы займов.
  useEffect(() => {
    setIsLoading(true);
    dispatch(getUserData());
  }, [dispatch]);

  // Если был переход на оплату и возврат после оплаты в приложение проверяем есть ли в LS вкладка,
  // с которой ушли
  useEffect(() => {
    let tab = JSON.parse(localStorage.getItem('tab'));
    if (tab) {
      setActiveTab(tab);
      localStorage.removeItem('tab');
    }
  }, []);

  // Если данные юзера получены, то мы отключаем глобальный лоудер
  useEffect(() => {
    if (userData && userData.КодОшибки === '0' && isLoading) {
      setIsLoading(false);
      // если нормально авторизировались то забираем доступные типы займой и возможные дополнительные поля-реквизиты для займов
      dispatch(getRequisites());
      dispatch(getLoans());
      dispatch(getDepositTypes());
      dispatch(getDepositRequisites());
    }
    if (serverResponse && serverResponse.status === 500) {
      setIsLoading(false);
      notification.error({
        message: 'Ошибка',
        description: 'Проблемы с сервером',
      });
    }
  }, [userData, dispatch, isLoading, serverResponse, goToLogin]);

  // Высчитываем высоту основного блока
  useEffect(() => {
    let a = document.querySelector('.main-header');
    let b = a.getBoundingClientRect();
    setMainHeight(height - b.height);
  }, [height]);

  // Если произошел редирект с оплаты, то считываем id и по апишке отправляем
  // успех у нас оплаты или неуспех (в апи учавствует флаг success или fail)
  useEffect(() => {
    let a = history.location.pathname.split('/');
    if (a[1] === 'payment') {
      // window.location.reload();
      let b = history.location.search.split('&');
      let c = b[0].split('=');
      // console.log(b, c);
      if (c[1]) {
        let vals = {
          sb_order_uuid: c[1],
        };
        // console.log(vals);
        setPaymentLoading(true);
        dispatch(sendPaymentStatus({ vals: vals, url: a[2] }));
      }
    }
  }, [history.location.pathname, history.location.search, dispatch]);

  // Если апишка нам ответила успехом, то показываем юзеру транзакция прошла успешно или произовшла ошибка.
  useEffect(() => {
    if (paymentServerResponse && paymentLoading && paymentServerResponse.status === 200) {
      setPaymentLoading(false);
      if (paymentServerResponse.url === 'success') {
        Modal.success({
          title: 'Готово!',
          content: 'Транзакция прошла успешно',
          centered: true,
          // onOk: () => {},
        });
        // window.location.reload();
      }
      if (paymentServerResponse.url === 'fail') {
        Modal.error({
          title: 'Ошибка!',
          content: 'Транзакция закончилась неудачей',
          centered: true,
          // onOk: () => {},
        });
      }
      dispatch(clearPaymentServerResponse());
      clearUrl();
    }
    // такое происходит если мы Id оплаты уже отправляли.
    // Если вдруг юзер туда-сюда-обратно в браузере кнопку тыкает history.goBack и history.goForward
    // Просто чистим ответ, ничего не показываем
    if (paymentServerResponse && paymentLoading && paymentServerResponse.status === 400) {
      setPaymentLoading(false);
      clearUrl();
      dispatch(clearPaymentServerResponse());
    }
  }, [paymentServerResponse, paymentLoading, dispatch, clearUrl]);

  const [sidebarOptions, setSidebarOptions] = useState([]);
  useEffect(() => {
    const mainSettings = config['1c_configs'].return.ГлавныеНастройки;

    if (config) {
      setSidebarOptions([
        {
          id: 'homeCard',
          title: 'Главная',
          text: 'Документы',
          show: true,
        },
        {
          id: 1,
          title: 'Заявки на рассрочку',
          show: mainSettings['ОтображатьЗаявкиНаЗайм'],
        },
        {
          id: 2,
          title: 'Договора рассрочки',
          show: mainSettings['ОтображатьЗаймы'],
        },
        {
          id: 3,
          title: 'Заявки на сбережения',
          show: mainSettings['ОтображатьЗаявкиНаСбережения'],
        },
        {
          id: 4,
          title: 'Сбережения',
          show: mainSettings['ОтображатьСбережения'],
        },
        {
          id: 5,
          title: 'Стандартные взносы',
          show: mainSettings['ОтображатьСтандартныеВзносы'],
        },
        {
          id: 6,
          title: 'Периодические взносы',
          show: mainSettings['ОтображатьПериодическиеВзносы'],
        },
        {
          id: 7,
          title: 'Сообщения',
          // show: true,
          show: mainSettings['ОтображатьСообщения'],
        },
        {
          id: 8,
          title: 'Контакты ',
          icon: 1,
          // show: true
        },
      ]);
    }
  }, [config]);

  useEffect(() => {
    if (sidebarOptions.length) {
      let a = sidebarOptions.find((el) => el.show);
      setActiveTab(a);
    }
  }, [setActiveTab, sidebarOptions]);

  return (
    <>
      <Loader isLoading={fileIsLoading} />
      <Loader isLoading={isLoading} />

      <Layout className='main-page'>
        <Header className='main-header'>
          <nav>
            <Link to='/' onClick={() => setActiveTab(initTab)}>
              <img src={config.logo ? `${BASE_API_PATH}${config.logo}` : logo} alt='logo' />
              <span className='main-header-title'>{config.name ? config.name : 'Рассрочка'}</span>
            </Link>
          </nav>
          <UserDropdown openChangeModal={() => setVisible(true)} />
        </Header>

        <Layout
          style={
            width > 767
              ? { minHeight: `${mainHeight}px` }
              : { minHeight: `${mainHeight}px`, padding: '0 0 6rem' }
          }
        >
          {width > 767 ? (
            <LeftSidebar
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              sidebarOptions={sidebarOptions}
              setSidebarOptions={setSidebarOptions}
              mainHeight={mainHeight}
            />
          ) : (
            <></>
          )}
          <div className='main-content-wrapper'>
            <Content className='main-content'>
              <TabsHolder
                activeTab={activeTab}
                isLoading={isLoading}
                setActiveTab={setActiveTab}
                sidebarOptions={sidebarOptions}
              />
            </Content>
            <ChangePasswordModal
              visible={visible}
              onCancel={() => {
                setVisible(false);
              }}
            />
          </div>
        </Layout>
        {width <= 767 && (
          <Footer className='main-footer'>
            {/* <div className='footer-text'>{config.footer_text ? config.footer_text : 'Личный кабинет'} Версия 1.2.4</div> */}
            <div className='main-footer-btn'>
              <div className='main-footer-btn-icon' onClick={() => setActiveTab(initTab)}>
                <IconHome color={activeTab == initTab ? '#000AFF' : '#333'} />
              </div>
              <div className='main-footer-btn-icon' onClick={() => setActiveTab(calcTab)}>
                <IconCalc color={activeTab == calcTab ? '#000AFF' : '#333'} />
              </div>
            </div>
            <div className='main-footer__loanBtn'>
              <Button
                type='primary'
                className='btn-send-loan'
                onClick={() => setVisibleLoan({ ...visibleLoan, loan: true })}
              >
                Подать заявку
              </Button>
            </div>
            <div className='main-footer-btn'>
              <div className='main-footer-btn-icon' onClick={() => setActiveTab(docTab)}>
                <IconDoc color={activeTab == docTab ? '#000AFF' : '#333'} />
              </div>
              <div className='main-footer-btn-icon' onClick={() => setActiveTab(profileTab)}>
                <IconUser color={activeTab == profileTab ? '#000AFF' : '#333'} />
              </div>
            </div>
            <LoanModal
              visible={visibleLoan.loan}
              onCancel={() => {
                setVisibleLoan({ ...visibleLoan, loan: false });
              }}
            />
          </Footer>
        )}
      </Layout>
    </>
  );
};

export default memo(MainPage);
