import React, { memo, useEffect, useState } from 'react';
import { Menu } from 'antd';
import './CardHome.less';
import { FormOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const CardHome = ({ activeTab, setActiveTab }) => {
  const [homeOptions, setHomeOptions] = useState([]);
  const config = useSelector((state) => state.config);

  useEffect(() => {
    const mainSettings = config['1c_configs'].return.ГлавныеНастройки;

    if (config) {
      setHomeOptions([
        {
          id: 'homeCard',
          title: 'Главная',
          text: 'Документы',
          show: false,
        },
        {
          id: 1,
          title: 'Заявки на рассрочку',
          show: mainSettings['ОтображатьЗаявкиНаЗайм'],
        },
        {
          id: 2,
          title: 'Договора рассрочки',
          show: mainSettings['ОтображатьЗаймы'],
        },
        {
          id: 7,
          title: 'Сообщения',
          show: mainSettings['ОтображатьСообщения'],
        },
        {
          id: 8,
          title: 'Контакты ',
          icon: 1,
          // show: true
        },
      ]);
    }
  }, [config]);

  useEffect(() => {
    if (homeOptions.length) {
      let a = homeOptions.find((el) => el.show);
      setActiveTab(a);
    }
  }, [setActiveTab, homeOptions]);
  // console.log(homeOptions);
  return (
    <Menu
      theme='dark'
      mode='inline'
      defaultSelectedKeys={['1']}
      selectedKeys={[`${activeTab.id}`]}
      className='__homeLink'
    >
      {homeOptions.length > 0 &&
        homeOptions
          .filter((el) => el.show)
          .map((el) => (
            <Menu.Item
              key={el.id}
              onClick={() => {
                setActiveTab(el);
                console.log(el);
              }}
            >
              <span>{el.title} </span>
              {el.icon && <FormOutlined />}
            </Menu.Item>
          ))}
    </Menu>
  );
};

export default memo(CardHome);

