import React from 'react';
import { Card } from 'antd';
import ESIALoanForm from './components/ESIALoanForm';

const Block3 = ({ active }) => {
  const { activeBlock } = active;

  return (
    <Card
      title='Заявка на рассрочку'
      className={activeBlock === 3 ? 'esia-block' : 'esia-block unactive-block'}
    >
      <ESIALoanForm/>
    </Card>
  );
};

export default Block3;