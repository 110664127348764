import React, { memo, useState, useEffect } from 'react';
import { Button } from 'antd';
import CardHome from '../../Layout/CardHome/CardHome';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';
import LoanModal from '../../Modals/LoanModal/LoanModal';
import ChevronDown from '../../../../../assets/images/chevron-down.svg';
import ChevronUp from '../../../../../assets/images/chevron-up.svg';
import { Menu } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import makeNumberPretty from '../../../../../heplers/makeNumberPretty';
import './Home.less';

const initVisible = { is: false, title: '', uid: '', type: '' };

const Home = ({ activeTab, setActiveTab, isLoading, sidebarOptions }) => {
  const [visible, setVisible] = useState(initVisible);
  const [showMore, setShowMore] = useState(false);
  const userData = useSelector((state) => state.user.data);
  const { width } = useWindowSize();
  const iframe =
    'https://yandex.ru/map-widget/v1/?um=constructor%3A8d928eb4d57405e1f16e9fbec6c0f0a9315509894cdd0d8c7c502fe33fbe4115&amp;source=constructor';

  const sum = (value) => {
    const res = userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма;
    if (Array.isArray(res)) {
      let total = res.reduce(
        (s, i) =>
          s +
          Number(i[value].Номинал) +
          Number(i[value].Комиссия) +
          Number(i[value].Пени) +
          Number(i[value].Комиссия) +
          Number(i[value].ПениКомиссия) +
          Number(i[value].Проценты),
        0,
      );

      return makeNumberPretty(total);
    }
    if (res instanceof Object) {
      const total =
        Number(res[value].Номинал) +
        Number(res[value].Комиссия) +
        Number(res[value].Пени) +
        Number(res[value].Комиссия) +
        Number(res[value].ПениКомиссия) +
        Number(res[value].Проценты);
      return makeNumberPretty(total);
    }
  };

  // Остаток по договорам
  const remainder = () => {
    if (userData.ИнформацияОЗаймах && userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма) {
      const total = sum('пПлатеж');
      if (
        Array.isArray(userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма) &&
        userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма.length !== 1
      ) {
        return (
          <p>
            Остаток по {userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма.length} договорам:
            <b>{total} ₽</b>
          </p>
        );
      } else {
        return (
          <p>
            Остаток по договору: <b>{total} ₽</b>
          </p>
        );
      }
    }
  };

  // Сумма оплаты в текущем месяце
  const paymentCurrentMonth = () => {
    if (userData.ИнформацияОЗаймах && userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма) {
      const date = new Date();
      const MONTH = [
        'январе',
        'феврале',
        'марте',
        'апреле',
        'мае',
        'июне',
        'июле',
        'августе',
        'сентябре',
        'октябре',
        'ноябре',
        'декабре',
      ];
      const total = sum('тПлатеж');
      return (
        <p>
          Сумма оплаты в {MONTH[date.getMonth()]}:<b>{total} ₽</b>
        </p>
      );
    }
  };
  // Просроченная задолженность
  const overdue = () => {
    if (userData.ИнформацияОЗаймах && userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма) {
      const total = sum('шПлатеж');
      return (
        <>
          {total !== 0 && (
            <p>
              Просроченная задолженность:{' '}
              <b style={{ color: '#FD0018' } }>{total} ₽</b>
            </p>
          )}
        </>
      );
    }
  };
  // Элемент списка оплаты
  const paymentsItem = (key, el, showMore) => {
    return (
      <p
        key={key}
        style={
          key === 0 ? { display: 'flex' } : showMore ? { display: 'flex' } : { display: 'none' }
        }
      >
        {key !== '' && <>{key + 1}. до</>} {makeSimpleData(el.ДатаПлатежа)}
        <b>
          {makeNumberPretty(
            Number(el.тПлатеж.Комиссия) +
              Number(el.тПлатеж.Номинал) +
              Number(el.тПлатеж.Пени) +
              Number(el.тПлатеж.ПениКомиссия) +
              Number(el.тПлатеж.Проценты),
          )}
           ₽
        </b>
      </p>
    );
  };

  return (
    <div className='homewrap'>
      <div className='homewrap__docs'>
        <div className='homewrap__menu'>
          <Menu
            theme='dark'
            mode='inline'
            defaultSelectedKeys={['1']}
            selectedKeys={[`${activeTab.id}`]}
            className='__homeLink'
          >
            {sidebarOptions.length > 0 &&
              sidebarOptions
                .filter((el) => el.show)
                .map(
                  (el) =>
                    el.id !== 'homeCard' && (
                      <Menu.Item
                        style={{ color: '#333' }}
                        key={el.id}
                        onClick={() => {
                          setActiveTab(el);
                        }}
                      >
                        {el.title}
                        {el.icon && <FormOutlined />}
                      </Menu.Item>
                    ),
                )}
          </Menu>
        </div>
      </div>

      <>
        {userData.ИнформацияОЗаймах &&
        userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма.length !== 0 ? (
          <>
            <div className='homewrap__info'>
              <div className='homewrap__title'>
                <span>Информация по задолженности</span>
              </div>
              {userData.ИнформацияОЗаймах && (
                <div className='homewrap__content'>
                  {remainder()}
                  {paymentCurrentMonth()}
                  {overdue()}
                </div>
              )}
            </div>
            <div className='homewrap__info'>
              <div className='homewrap__title'>
                <span>Ближайшие оплаты</span>
              </div>
              <div className='homewrap__content paymentsList'>
                {Array.isArray(userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма) && (
                  <>
                    {userData.ИнформацияОЗаймах &&
                      userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма
                        .sort((a, b) => Date.parse(a.ДатаПлатежа) - Date.parse(b.ДатаПлатежа))
                        .map((el, key) => paymentsItem(key, el, showMore))}
                    {!showMore ? (
                      <span onClick={() => setShowMore(true)}>
                        Развернуть <img src={ChevronDown} alt='Down' />
                      </span>
                    ) : (
                      <span onClick={() => setShowMore(false)}>
                        Свернуть
                        <img src={ChevronUp} alt='Up' />
                      </span>
                    )}
                  </>
                )}
                {!Array.isArray(userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма) && (
                  <>
                    {userData.ИнформацияОЗаймах &&
                      paymentsItem('', userData.ИнформацияОЗаймах.ИнформацияОДоговореЗайма, true)}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            {!isLoading && (
              <div className='homewrap__info'>
                <div className='homewrap__content'>
                  <span>У вас нет активных договоров</span>
                </div>
              </div>
            )}
          </>
        )}
      </>

      <div className='homewrap__map'>
        <div className='homewrap__title'>
          <span>Ближайшие точки оплат</span>
        </div>
        <iframe title='yandexmap' src={iframe} width='100%' height='250' frameBorder='0' />
      </div>
      <div className='homewrap__loanBtn'>
        <Button
          type='primary'
          className='btn-send-loan'
          onClick={() => setVisible({ ...visible, loan: true })}
        >
          Подать заявку
        </Button>
      </div>

      <LoanModal
        visible={visible.loan}
        onCancel={() => {
          setVisible({ ...visible, loan: false });
        }}
      />
    </div>
  );
};

export default memo(Home);
