import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import makeNumberPretty from '../../../../../heplers/makeNumberPretty';
import './LoanTable.less';

const getTableWidth = () => {
  let arr = document.querySelectorAll('th.ant-table-cell.th-w');
  // console.log(arr)
  let a = Array.from(arr);
  let b = a.map((el) => {
    let bb = el.getBoundingClientRect();
    return bb.width;
  });
  // console.log(b)
  let c = b.reduce((acc, el) => el + acc, 0);
  let cc = b.filter((el, ind) => ind !== b.length - 1).reduce((acc, el) => el + acc, 0);
  let ccc = b[b.length - 1];

  return { full: c, f: cc, s: ccc };
};

const LoanTable = ({ sum }) => {
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const [tableDataSum, setTableDataSum] = useState({
    Дни: '0',
    Остаток: '',
    Номинал: '0',
    Проценты: '0',
    Комиссия: '0',
    Всего: '0',
  });
  const [secondTableData, setSecondTableData] = useState([]);
  const [tableWidth, setTableWidth] = useState({ full: 0, f: 0, s: 0 });

  const creditTable = useSelector((state) => state.user.credit.creditTable);

  let columns = [
    {
      title: '№',
      key: 'ДатаНачала',
      width: 17,
      className: 'th-w',
      // responsive: ['md'],
      align: 'center',
      render: (text, record) => (
        <p className='table-item' style={{ width: '17px' }}>
          {record.НомерСтроки}
        </p>
      ),
    },
    // {
    //   title: 'Дни',
    //   key: 'Дни',
    //   width: 70,
    //   className: 'th-w',
    //   align: 'center',
    //   render: (text, record) => (
    //     <p className='table-item' style={{ width: '70px' }}>
    //       {record.Дни}
    //     </p>
    //   ),
    // },
    {
      title: 'Платеж',
      key: 'Всего',
      width: 75,
      className: 'th-w',
      // responsive: ['md'],
      align: 'center',
      render: (text, record) => (
        <p className='table-item table-item__border' style={{ width: '75px' }}>
          {makeNumberPretty(record.Всего)}
        </p>
      ),
    },
    // {
    //   title: 'Сумма платежей за период',
    //   align: 'center',
    //   children: [
    //     {
    //       title: 'Номинал',
    //       key: 'Номинал',
    //       className: 'th-w',
    //       width: 130,
    //       align: 'center',
    //       render: (text, record) => (
    //         <p className='table-item' style={{ width: '130px' }}>
    //           {makeNumberPretty(record.Номинал)}
    //         </p>
    //       ),
    //     },
    //     {
    //       title: 'Проценты',
    //       key: 'Проценты',
    //       width: 130,
    //       align: 'center',
    //       render: (text, record) => (
    //         <p className='table-item' style={{ width: '130px' }}>
    //           {makeNumberPretty(record.Проценты)}
    //         </p>
    //       ),
    //     },
    //     {
    //       title: 'Комиссия',
    //       key: 'Комиссия',
    //       width: 130,
    //       align: 'center',
    //       render: (text, record) => (
    //         <p className='table-item' style={{ width: '130px' }}>
    //           {makeNumberPretty(record.Комиссия)}
    //         </p>
    //       ),
    //     },
    //     {
    //       title: 'Всего',
    //       key: 'Всего',
    //       width: 130,
    //       align: 'center',
    //       render: (text, record) => (
    //         <p className='table-item table-item__border' style={{ width: '130px' }}>
    //           {makeNumberPretty(record.Всего)}
    //         </p>
    //       ),
    //     },
    //   ],
    // },
    {
      title: 'Остаток',
      align: 'center',
      className: 'th-w',
      width: 100,
      render: (text, record) => (
        <p className='table-item' style={{ width: 'auto' }}>
          {record.Остаток !== '0' && makeNumberPretty(record.Остаток)}
        </p>
      ),
    },
  ];

  const column2 = [
    {
      title: 'Единовременные взносы при предоставлении рассрочки',
      key: 'name',
      align: 'center',
      render: (text, record) => (
        <p className='table-item' style={{ width: `${tableWidth.f - 16}px` }}>
          {record.Наименование}
        </p>
      ),
    },
    {
      title: 'Сумма',
      key: 'sum2',
      align: 'center',
      render: (text, record) => (
        <p className='table-item' style={{ width: `${tableWidth.s - 16}px` }}>
          {makeNumberPretty(record.Сумма)}
        </p>
      ),
    },
  ];

  useEffect(() => {
    if (secondTableData.length && tableData.length) {
      let a = getTableWidth();
      setTableWidth(a);
    }
  }, [secondTableData, tableData]);

  useEffect(() => {
    if (!tableData.length && creditTable.ГрафикПлатежей) {
      if (
        creditTable.ГрафикПлатежей.ТаблицаГрафика &&
        creditTable.ГрафикПлатежей.ТаблицаГрафика.СтрокаГрафика
      ) {
        let data = creditTable.ГрафикПлатежей.ТаблицаГрафика.СтрокаГрафика;
        if (!Array.isArray(data)) {
          let a = [];
          a.push(data);
          data = a;
        }
        let a = data.find((el) => el.Комиссия !== '0');
        if (!a) {
          let b = columns.map((el) => {
            if (el.title === 'Сумма платежей за период') {
              let c = el.children.filter((ell) => ell.title !== 'Комиссия');
              el.children = c;
              return el;
            } else {
              return el;
            }
          });
          setTableColumns(b);
        } else {
          setTableColumns(columns);
        }

        let aa, bb, cc, dd, ff;
        aa = data.reduce((acc, el) => +el.Дни + acc, 0);
        bb = data.reduce((acc, el) => +el.Номинал + acc, 0);
        cc = data.reduce((acc, el) => +el.Проценты + acc, 0);
        dd = data.reduce((acc, el) => +el.Комиссия + acc, 0);
        ff = data.reduce((acc, el) => +el.Всего + acc, 0);

        setTableDataSum({
          ...tableDataSum,
          Дни: aa,
          Номинал: bb,
          Проценты: cc,
          Комиссия: dd,
          Всего: ff,
        });
        // let ggg = data.map((el,ind) => {return {...el, key: ind}})

        setTableData(data);
      }
    }
    console.log(creditTable);
    console.log(secondTableData);
    if (
      !secondTableData.length &&
      creditTable.ВзносыПриВыдаче &&
      creditTable.ВзносыПриВыдаче.СтрокаТаблицы
    ) {
      let data = creditTable.ВзносыПриВыдаче.СтрокаТаблицы;
      let arr = [];
      if (!Array.isArray(data)) {
        let obj = {
          Сумма: data.Сумма,
          Наименование: data.Взнос.Наименование,
        };
        arr = [obj];
      } else {
        arr = data.map((el) => {
          return {
            Сумма: el.Сумма,
            Наименование: el.Взнос.Наименование,
          };
        });
      }
      setSecondTableData(arr);
    }
  }, [columns, creditTable, secondTableData.length, tableData.length, tableDataSum]);

  return (
    <>
      {tableColumns.length && tableData.length ? (
        <Table
          rowKey={(record) => record.НомерСтроки}
          columns={tableColumns}
          dataSource={tableData}
          bordered
          size='small'
          pagination={false}
          summary={() => (
            <Table.Summary.Row align='center'>
              <Table.Summary.Cell index={0} className='table-item__border'>
                ИТОГО
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} className='table-item__border'>
                {tableDataSum.Остаток}
              </Table.Summary.Cell>

              <Table.Summary.Cell
                index={tableDataSum.Комиссия ? 6 : 5}
                className='table-item__border'
              >
                {makeNumberPretty(tableDataSum.Всего)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      ) : (
        <></>
      )}
      {secondTableData.length && tableWidth.full ? (
        <Table
          columns={column2}
          dataSource={secondTableData}
          bordered
          size='small'
          style={{ marginTop: '1rem', width: `${tableWidth.full}px` }}
          pagination={false}
          summary={() => (
            <Table.Summary.Row align='center'>
              <Table.Summary.Cell index={0} className='table-item__border'>
                Итого
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className='table-item__border'>
                {makeNumberPretty(secondTableData.reduce((acc, el) => acc + +el.Сумма, 0))}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default LoanTable;
