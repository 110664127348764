import React, { memo, useState, useEffect } from 'react';
import { Form, Input, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { useSelector } from 'react-redux';


const Datepick = ({ isLoading }) => {
  const config = useSelector((state) => state.config);
  // console.log(config);

  return (
    
    <Form.Item
        name='birthday'
        label='Укажите дату рождения'
        rules={[
          {
            required: true,
            message: 'Заполните дату рождения',
          },
        ]}
      >
        <DatePicker locale={locale} format='DD.MM.YYYY' />
    </Form.Item>
  );
};

export default memo(Datepick);
