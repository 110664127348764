import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import getPasswordRules from '../../../heplers/getPasswordRules';
import { Button, Modal } from 'antd';

const getSymbol = (length) => {
  if(length === 1) {
    return 'символ'
  } else if (length > 1 && length < 5) {
    return 'символа'
  } else {
    return 'символов'
  };
};

const PasswordRules = () => {
  const config = useSelector((state) => state.config);
  const [passwordRules, setPasswordRules] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    // console.log('showModal click', setOpen);
    // setIsModalOpen(true);
    setOpen(true);
  };

  const handleOk = () => {
    // setIsModalOpen(false);
    setOpen(false);
  };

  const handleCancel = () => {
    // setIsModalOpen(false);
    setOpen(false);
  };
  useEffect(() => {
    if (
      config['1c_configs'] &&
      config['1c_configs'].return &&
      config['1c_configs'].return.ГлавныеНастройки &&
      config['1c_configs'].return.ГлавныеНастройки.ПараметрыПароля
    ) {
      let params = config['1c_configs'].return.ГлавныеНастройки.ПараметрыПароля.replace(/\s+/g, '').split(',')
      let arr = getPasswordRules(params);
      setPasswordRules(arr);
    };
  }, [config]);

  return (
    <>
      <Button type='link' className='btn btn-info' onClick={showModal}>
        Требования к паролю
      </Button>
      <Modal 
        title="Требования к паролю:"
        
        className='mobile mobile__modal'
        // open={isModalOpen}
        visible={open}
        onOk={handleOk} 
        onCancel={handleCancel}
        okText='Понятно'
        footer={[
          
          <Button type="primary" key="back" onClick={handleOk}>
            Понятно
          </Button>
          
        ]}
        >
        <ol>
        {passwordRules.map(el => {
            if(el.key === 'Длина'){
              return <li key={el.key}>Минимальная длина пароля <strong>{el.length} {getSymbol(el.length)}</strong>;</li>
            };
            if(el.key === 'Спец'){
              return <li key={el.key}> Должен содержать минимум <strong>{el.length} спец{getSymbol(el.length)}</strong>;</li>
            }
            if(el.key === 'Врег'){
              return <li key={el.key}> Должен содержать минимум <strong>{el.length} {getSymbol(el.length)} в верхнем регистре</strong>;</li>
            }
            if(el.key === 'Цифры'){
              return <li key={el.key}> Должен cодержать минимум <strong>{el.length} цифру;</strong></li>
            }
            return el
          })
        }
        </ol>
      </Modal>
    </>
    
  );
};

export default memo(PasswordRules);
