import React, { memo } from 'react';
import { Popover } from 'antd';

import useWindowSize from '../../../../../hooks/useWindowSize';

const UserCart = () => {
  const { width } = useWindowSize();
//   const text = <span></span>;
  const content = (
    <div>
      <p>Извините, функция ещё в разработке</p>

    </div>
  );
  

  return (
    <div className='main-header-user'>
        <Popover placement="bottomRight" 
            // title={text} 
            content={content}>
                <span className='__cart'><i className='count'>0</i></span>
        </Popover>
    </div>
    
  );
};

export default memo(UserCart);
