import React, { memo, useState } from 'react';
import { Form, Input } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

const Phone = () => {
  const [isPhone, setIsPhone] = useState(false);
  return (
    <Form.Item
      name='phone'
      label='Номер мобильного телефона'
      rules={[
        {
          required: true,
          message: 'Заполните номер мобильного телефона',
        },
        { max: 10, message: 'Номер слишком длинный' },
        { min: 10, message: 'Номер слишком короткий' },
      ]}
      hasFeedback
    >
      <Input
        // addonBefore='+7'
        type='number'
        autoComplete='off'
        autoCorrect='off'
        spellCheck='false'
        prefix={!isPhone ? <PhoneOutlined className='site-form-item-icon' /> : '+7'}
        onFocus={(e) => setIsPhone(true)}
        onBlur={(e) => (!e.target.value ? setIsPhone(false) : undefined)}
        allowClear
      />
    </Form.Item>
  );
};

export default memo(Phone);